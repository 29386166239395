import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["country", "state", "stateLabel"]

  connect() {
    this.updateState()
  }

  updateState() {
    const isUS = this.countryTarget.value === "us"
    this.stateTarget.classList.toggle("hidden", !isUS)
    this.stateTarget.toggleAttribute("disabled", !isUS)

    // Only toggle 'stateLabel' if it exists
    if (this.hasStateLabelTarget) {
      this.stateLabelTarget.classList.toggle("hidden", !isUS)
    }
  }
}
