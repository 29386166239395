import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    setTimeout(this.animate, 3000, this.element)
  }

  animate(element) {
    element.classList.add("flash--fade_out")
  }

  remove() {
    this.element.remove()
  }
}
