/* Implements a a toggleable switch that can hide or show specific content when
 * it's checked/unchecked, that can uncheck tagged input elements when unchecked
 * and check them back when checked, and which can change the toggle's label
 *
 * Targets:
 *   * `toggleInput` - points to the checkbox that represents if the toggle is
 *                     active or not. This target is used to determine if the
 *                     toggle is checked or unchecked, and it's value is changed
 *                     when the `toggle` method is called
 *   * `toggleLabel` - this target's text will be set to the specified
 *                     checked/unchecked message
 *   * `contentToHide` - taggs an element to be hidden when the toggle is
 *                       checked and shown when it's disabled
 *   * `contentToShow` - taggs an element to be shown when the toggle is
 *                       unchecked and hidden when it's disabled
 *   * `inputToDisable` - taggs an element to be disabled when the toggle is
 *                        unchecked and enabled when the togle is checked. Useful
 *                        for omitting form data of some hidden content
 *
 * Values:
 *   * `hiddenClass` - Specifies which class will be applied to hidden elements
 *                     and removed from shown elements
 *   * `checkedMessage` - Sets the `toggleLabel`'s text when the toggle is
 *                        checked. If ommited the text is unchanged.
 *   * `uncheckedMessage` - Sets the `toggleLabel`'s text when the toggle is
 *                         unchecked. If ommited the text is unchanged.
 *
 * Example:
 *
 * ```javascript
 *   <div data-controller="toggle"
 *        data-toggle-hidden-class-value="my-custom-hidden-class"
 *        data-toggle-checked-message-value="On"
 *        data-toggle-unchecked-message-value="Off"
 *   >
 *     <div class="header">
 *       <div class="toggle-switch">
 *         <input type="checkbox" id="toggle-1">
 *         <label class="toggle-label" for="toggle-1">
 *           <span data-toggle-target="toggleLabel">On</span>
 *           <span class="toggle-track"></span>
 *         </label>
 *       </div>
 *     </div>
 *     <div class="content">
 *       <div class="content__subcontent" data-toggle-target="contentToHide">
 *         <p>This content is shown when the toggle is unchecked</p>
 *         <p>But it's hidden when the toggle is checked</p>
 *       </div>
 *       <div class="content__subcontent" data-toggle-target="contentToHide">
 *         <p>This content is shown when the toggle is checked</p>
 *         <p>But it's hidden when the toggle is unchecked</p>
 *
 *         <label for="test_input">This input is disabled when the toggle is unchecked</p>
 *         <input id="test_input" type="text" data-toggle-target="inputToDisable" />
 *
 *         <label for="test_input">This input is disabled when the toggle is unchecked</p>
 *         <input id="test_input" type="textarea" data-toggle-target="inputToDisable" />
 *       </div>
 *     </div>
 *   </div>
 * ```
 *
 */
import ApplicationController from "./application_controller"

export default class ToggleController extends ApplicationController {
  static targets = ["toggleInput", "toggleLabel", "contentToHide",
                    "contentToShow", "inputToDisable"]
  static values = {
    hiddenClass: String,
    checkedMessage: String,
    uncheckedMessage: String
  }

  connect() {
    if (!this.hasHiddenClassValue) this.hiddenClassValue = "hidden"

    if (this.active) {
      this.check()
    }
    else {
      this.uncheck()
    }
  }

  toggle() {
    if (this.active) {
      this.check()
    }
    else {
      this.uncheck()
    }
  }

  check() {
    if (this.hasToggleInputTarget) this.toggleInputTarget.checked = true
    if (this.hasCheckedMessageValue) this.setLabel(this.checkedMessageValue)

    if (this.hasContentToHideTarget) {
      this.applyClassToTarget("add",
                              this.hiddenClassValue,
                              this.contentToHideTargets)
    }

    if (this.hasInputToDisableTarget) {
      this.applyDisabledToTargets(false, this.inputToDisableTargets)
    }

    if (this.hasContentToShowTarget) {
      this.applyClassToTarget("remove",
                              this.hiddenClassValue,
                              this.contentToShowTargets)
    }
  }

  uncheck() {
    if (this.hasToggleInputTarget) this.toggleInputTarget.checked = false
    if (this.hasUncheckedMessageValue) this.setLabel(this.uncheckedMessageValue)

    if (this.hasContentToHideTarget) {
      this.applyClassToTarget("remove",
                              this.hiddenClassValue,
                              this.contentToHideTargets)
    }

    if (this.hasInputToDisableTarget) {
      this.applyDisabledToTargets(true, this.inputToDisableTargets)
    }

    if (this.hasContentToShowTarget) {
      this.applyClassToTarget("add",
                              this.hiddenClassValue,
                              this.contentToShowTargets)
    }
  }

  enable() {
    this.toggleInputTarget.disabled = false
    this.toggleInputTarget.closest("div.checkbox").classList.remove("disabled")
  }

  disable() {
    this.toggleInputTarget.disabled = true
    this.toggleInputTarget.closest("div.checkbox").classList.add("disabled")
  }

  applyClassToTarget(action, className, targets) {
    targets.forEach((target) => {
      if (action === "add") {
        target.classList.add(className)
      }
      else if (action === "remove") {
        target.classList.remove(className)
      }
    })
  }

  setLabel(message) {
    if (!this.hasToggleLabelTarget) return

    this.toggleLabelTarget.innerText = message
  }

  applyDisabledToTargets(disabled = targets) {
    targets.forEach((target) => {
      target.disabled = disabled
    })
  }

  get active() {
    if (!this.hasToggleInputTarget) return false

    return this.toggleInputTarget.checked
  }
}
