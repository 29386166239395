import ApplicationController from "./application_controller"

// This controller is responsible for managing checkboxes in any kind of
// bulk selection - like bulk action tables.
//
// Any selection is persisted in the current JS browser session. This means that
// if the user closes and re-opens their browser, the selection will be lost.
//
// There are two modes of operation:
// * ONLY - in which the action should be applied to *only* the selected IDs
// * EXCEPT - in which the action should be applied to *all* IDs *except* the
//            selected ones
//
// Having these two modes allows for a more intuitive user experience, simpler
// backend implementation, and better performance (in terms of memory usage).
//
// From the backend side there are two scenarios depending on the mode:
// * ONLY - in which case we should do `where(id: params[:id]&.split(","))`
// * EXCEPT - in which case we should do `where.not(id: params[:id]&.split(","))`
//
// This controller isn't intended to be inherited from or extended. Instead, to
// use it you should access it through an outlet on another Stimulus controller.
// And/or you can listen for events that this controller produces on it's
// element.
//
// AVOID holding a reference to this controller in your own controller! This
// will cause a memory leak. And don't rely on the `ids` and `mode` references
// to be up to date! Instead, listen for the events that this controller
// publishes.
//
// The available events are:
// * bulk-selection:willChangeMode - fired before the mode changes
// * bulk-selection:didChangeMode - fired after the mode changes
// * bulk-selection:willChangeIds - fired before the selected ids change
// * bulk-selection:didChangeIds - fired after the selected ids change
export default class extends ApplicationController {
  ONLY_MODE_VALUE = "ONLY"
  EXCEPT_MODE_VALUE = "EXCEPT"
  STORAGE_NAMESPACE = "bulk_selection_v1" // if you want to bust the selection cache, change this value
  MODE_STORAGE_KEY = "mode"
  IDS_STORAGE_KEY = "ids"

  static targets = [ "selectCheckbox", "selectAllCheckbox" ]
  static values = {
    namespace: String,
    count: {
      type: Number,
      default: 0
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  //           Handle apperance and dissaperance of checkboxes                //
  //////////////////////////////////////////////////////////////////////////////

  selectAllCheckboxTargetConnected(target) {
    if (!target) return

    this.updateSelectAllCheckbox(null, { target: target })
  }

  selectCheckboxTargetConnected(target) {
    if (!target?.dataset?.id) return

    target.checked = this.isSelected(target.dataset.id)
  }

  // This is left as a warning to future developers. This method is not needed,
  // and decrementing the count SHOULD be handeled explicitly.
  // Changing the count on disconnect DOES NOT count rows/elements that were
  // removed but weren't visible on the page. E.g. when someone selects all
  // rows (through the select all checkbox) and deletes them.
  //
  // selectCheckboxTargetDisconnected(target) {
  //   if (!target?.dataset?.id) return
  //
  //   this.setSelected(target.dataset.id, false)
  //   this.countValue = this.countValue - 1
  // }

  //////////////////////////////////////////////////////////////////////////////
  //                          Event handlers                                  //
  //////////////////////////////////////////////////////////////////////////////

  selectAll(event) {
    event?.preventDefault()

    const checked = event?.target?.checked || false

    if (checked) {
      this.setAllSelected(event)
    }
    else {
      this.setAllDeSelected(event)
    }
  }

  select(event) {
    if (!event) return

    event.preventDefault()

    const id = event.target.dataset.id
    const checked = event.target.checked || false

    this.setSelected(id, checked)
    this.updateSelectAllCheckbox()

    // Handles an edge case where the user selects or deselects all checkboxes.
    // In that case we want to behave as if the user clicked the select all
    // checkbox.
    if (this.allSelected) {
      this.setAllSelected(event)
    }
    else if (this.noneSelected) {
      this.setAllDeSelected(event)
    }
  }

  setAllSelected(event) {
    event?.preventDefault()

    this.mode = this.EXCEPT_MODE_VALUE
    this.ids = []

    this.updateSelectCheckboxes()
    this.updateSelectAllCheckbox()
  }

  setAllDeSelected(event) {
    event?.preventDefault()

    this.mode = this.ONLY_MODE_VALUE
    this.ids = []

    this.updateSelectCheckboxes()
    this.updateSelectAllCheckbox()
  }

  updateCount(event) {
    if (!event) return

    const target = event.target
    if (!target) return

    // Prevent double count errors
    if (target.dataset.counted) return
    target.dataset.counted = true

    if (target.dataset.count) {
      this.countValue = parseInt(target.dataset.count || 0)
    }
    else if (target.dataset.incrementCount) {
      this.countValue += parseInt(target.dataset.incrementCount)
    }
    else if (target.dataset.decrementCount) {
      this.countValue -= parseInt(target.dataset.decrementCount)
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  //                              Public methods                              //
  //////////////////////////////////////////////////////////////////////////////

  get allSelected() {
    if (this.isModeOnly) {
      return this.countValue === this.ids.length
    }

    return this.ids.length === 0
  }

  get noneSelected() {
    if (this.isModeOnly) {
      return this.ids.length === 0
    }

    return this.countValue === this.ids.length
  }

  get count() {
    if (this.isModeOnly) {
      return this.ids.length
    }

    return this.countValue - this.ids.length
  }

  //////////////////////////////////////////////////////////////////////////////
  //                             Private methods                              //
  //////////////////////////////////////////////////////////////////////////////

  updateSelectAllCheckbox(event, options) {
    const target = options?.target || event?.target ||
      this.hasSelectAllCheckboxTarget && this.selectAllCheckboxTarget

    if (!target) return

    if (this.allSelected) {
      target.checked = true
      target.indeterminate = false
    }
    else if (this.noneSelected) {
      target.checked = false
      target.indeterminate = false
    }
    else {
      target.checked = false
      target.indeterminate = true
    }
  }

  updateSelectCheckboxes() {
    this.selectCheckboxTargets.forEach(target => {
      target.checked = this.isSelected(target.dataset.id)
    })
  }

  setSelected(id, value) {
    if (!id) return

    const selected = !!value

    // Add the ID to the list if the mode is ONLY and the checkbox is checked
    if (this.isModeOnly) {
      if (selected) {
        this.ids = [...this.ids, id.toString()]
      }
      else if (!selected) {
        this.ids = this.ids.filter(x => x !== id.toString())
      }
    }
    // Add the ID to the list if the mode is EXCEPT and the checkbox is un-checked
    else {
      if (selected) {
        this.ids = this.ids.filter(x => x !== id.toString())
      }
      else if (!selected) {
        this.ids = [...this.ids, id.toString()]
      }
    }
  }

  withEventNotification(action, name, data, callback) {
    const actionName = `${action.charAt(0).toUpperCase()}${action.slice(1)}`

    data = data || {}
    data.controller = this

    this.sendEventFor(`will${actionName}`, name, data)

    const result = callback()

    this.sendEventFor(`did${actionName}`, name, data)

    return result
  }

  sendEventFor(action, name, data) {
    const methodName = `${action}${name.charAt(0).toUpperCase()}${name.slice(1)}`

    const event = new CustomEvent(`bulk-selection:${methodName}`, {
      bubbles: false,
      detail: data,
    })

    this.element.dispatchEvent(event)
  }

  //////////////////////////////////////////////////////////////////////////////
  //                                Storage                                   //
  //////////////////////////////////////////////////////////////////////////////

  get isModeOnly() {
    return this.mode === this.ONLY_MODE_VALUE
  }

  get isModeExcept() {
    return this.mode === this.EXCEPT_MODE_VALUE
  }

  get mode() {
    return this.storage.getItem(this.idFrom(this.MODE_STORAGE_KEY)) || this.ONLY_MODE_VALUE
  }

  set mode(value) {
    if (value !== this.ONLY_MODE_VALUE && value !== this.EXCEPT_MODE_VALUE) {
      throw(`Trying to set invalid value for bulk selection mode: ${value}`)
    }

    this.withEventNotification("change", "mode", { from: this.mode, to: value }, () => {
      this.storage.setItem(this.idFrom(this.MODE_STORAGE_KEY), value)
    })
  }

  isSelected(id) {
    if (!id) return false

    const includes = this.ids.includes(id.toString())

    if (this.isModeOnly) return includes

    return !includes
  }

  get ids() {
    try {
      return JSON.parse(this.storage.getItem(this.idFrom(this.IDS_STORAGE_KEY)) || "[]")
    }
    catch (e) {
      console.warn("Failed to fetch ids for bulk selection", e)
    }

    return []
  }

  set ids(value) {
    this.withEventNotification("change", "ids", { from: this.ids, to: value }, () => {
      this.storage.setItem(this.idFrom(this.IDS_STORAGE_KEY), JSON.stringify(value))
    })
  }

  idFrom(id) {
    return [
      this.STORAGE_NAMESPACE,
      this.element.id,
      this.namespaceValue,
      id
    ].filter(x => !!x).join("--")
  }

  get storage() {
    return sessionStorage
  }
}
