import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["assigned", "unassigned", "assignedTab", "unassignedTab", "allTab", "submitButton"]

  connect() {
    this.initializeTabs()
    this.initializeDropdowns()
  }

  showAssigned() {
   this.hideItems(this.unassignedTargets)
   this.showItems(this.assignedTargets)
   this.triggerSearch()
  }

  showUnassigned() {
    this.showItems(this.unassignedTargets)
    this.hideItems(this.assignedTargets)
    this.triggerSearch()
  }

  showAll() {
   this.showItems(this.unassignedTargets)
   this.showItems(this.assignedTargets)
   this.triggerSearch()
  }

  hideItems(items) {
    items.forEach(item => {
      item.classList.add("hidden")
      item.classList.remove("tabFiltered")
    })
  }

  showItems(items) {
    items.forEach(item => {
      item.classList.remove("hidden")
      item.classList.add("tabFiltered")
    })
  }

  triggerSearch() {
    const event = new CustomEvent("smart_lock:apply-search")

    window.dispatchEvent(event)
  }

  enableSubmitButton() {
    this.submitButtonTargets.forEach(submit => submit.disabled = false)
  }

  initializeTabs() {
    if (this.unassignedTargets.length > 0) {
      this.showUnassigned()
      this.unassignedTabTarget.classList.add("active")
    }
    else {
      this.showAll()
      this.allTabTarget.classList.add("active")
    }
  }

  initializeDropdowns() {
    $("select.selectize-inside-overflow-block").selectize(
      {
        dropdownParent: "body",
        onChange: () => {
          const event = new CustomEvent("smart-lock:mapping-changed")
          window.dispatchEvent(event)
        }
      }
    )
  }

  fetchLocksByLocation(event) {
    window.location.search = "location=" + event.target.value
  }
}
