import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["unitName", "listingName"]

  connect() {
    this.searchMatchClasses = ["hidden"]
  }

  checkIfMatches() {
    if (!this.element.classList.contains("tabFiltered")) {
      return
    }

    const search_query = event.detail.search_query.toLowerCase()

    if (search_query.length == 0) {
      this.element.classList.remove(...this.searchMatchClasses)
      return
    }
    else {
      this.element.classList.add(...this.searchMatchClasses)
    }

    this.searchTargets(search_query)
  }

  searchTargets(search_query) {
    if (
      this.search(this.listingNameTarget, search_query) ||
      this.search(this.unitNameTarget, search_query)
    ) {
      this.element.classList.remove(...this.searchMatchClasses)
    }
  }

  search(target, query) {
    return target.textContent.toLowerCase().includes(query)
  }
}
