import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    buildingName: String,
    intercomSelectedWarningMessage: String
  }

  select(event) {
    event.preventDefault()
    event.stopPropagation()

    const s = this.application.getControllerForElementAndIdentifier(this.element, "select-with-search").selectize

    const currentAlertDiv = this.element.parentElement.querySelector(".alert.alert-warning")
    if (s.getOption(s.getValue())[0].dataset.isIntercom === "\"true\"") {
      if (!currentAlertDiv) {
        const alertDiv = document.createElement("div")
        alertDiv.classList.add("alert", "alert-dismissible", "alert-warning", "flex", "justify-between", "items-center", "m-b-none")

        alertDiv.innerHTML = `
        <div style="margin-right: 10px;"><img src="/assets/icons/solid/exclamation-triangle.svg"></div>
        <div>${this.intercomSelectedWarningMessageValue}</div>
        <div><button class="close close--darker font-normal text-xs" data-dismiss="alert" aria-label="close"><span aria-hidden="true">Dismiss</span></button></div>
        `
        this.element.parentElement.append(alertDiv)
      }
    } else {
      currentAlertDiv?.remove()
    }
  }
}
