import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["integrator", "bindingsContainer", "bindingTemplate",
                    "integratorFields", "integratorFieldsTemplate" ]
  static values = {
    fieldsUrl: String
  }

  updateFieldsForIntegrator() {
    if (!this.hasIntegratorFieldsTarget) return
    if (!this.hasFieldsUrlValue) return

    const formUrl = new URL(this.fieldsUrlValue)
    formUrl.searchParams.set("event_hook_integration[integrator]", this.integratorTarget.value)

    this.integratorFieldsTarget.src = formUrl
  }

  addBinding() {
    if (!this.hasBindingTemplateTarget || !this.hasBindingsContainerTarget) {
      return
    }

    const templateHtml = this.bindingTemplateTarget.innerHTML
    var newBinding = document.createElement("div")
    newBinding.innerHTML = templateHtml
    newBinding = newBinding.firstChild

    this.bindingsContainerTarget.appendChild(newBinding)
  }
}
