import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["contentToHide", "contentToShow", "toggleableField", "dropDownSelect"]

  connect() {
    if (this.hasDropDownSelectTarget) {
      this.hideAndDisableExcept(this.dropDownSelectTarget.value)
    }
    this.setToggles()
  }

  toggleContent(event) {
    if (event.target.checked) {
      event.target.nextElementSibling.children[0].innerText = "Enabled"
      this.contentToHideTargets.map((target) => target.classList.remove("hidden"))
      this.contentToShowTargets.map((target) => target.classList.add("hidden"))
    } else {
      event.target.nextElementSibling.children[0].innerText = "Off"
      this.contentToHideTargets.map((target) => target.classList.add("hidden"))
      this.contentToShowTargets.map((target) => target.classList.remove("hidden"))
    }
  }

  dropDownToggleWithDisable(event) {
    this.hideAndDisableExcept(event.target.value)
  }

  setToggles() {
    const checkboxInput = Array.from(this.element.querySelectorAll("input[type='checkbox']"))[0]
    if (!checkboxInput) {
      return
    }
    if (checkboxInput.checked) {
      checkboxInput.nextElementSibling.children[0].innerText = "Enabled"
    } else {
      checkboxInput.nextElementSibling.children[0].innerText = "Off"
    }
  }

  hideAndDisableExcept(fieldName) {
    this.toggleableFieldTargets.map((field) => {
      if (fieldName === field.dataset.fieldName) {
        field.classList.remove("hidden")
        // querySelectorAll returns a NodeList, we cast it to Array for easier iteration
        Array.from(field.querySelectorAll("input")).map((el) => el.disabled = false)
      } else {
        field.classList.add("hidden")
        Array.from(field.querySelectorAll("input")).map((el) => el.disabled = true)
      }
    })
  }
}
