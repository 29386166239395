import * as Sentry from "@sentry/browser"
import environment from "../../shared/env"

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: environment,
  release: `core-js@${import.meta.env.VITE_APP_VERSION}`,
})

Sentry.setTag("app_version", import.meta.env.VITE_APP_VERSION)
Sentry.setTag("region", import.meta.env.VITE_APP_REGION)
