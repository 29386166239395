import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  get csrfToken() {
    const tokenTag = document.querySelector("meta[name='csrf-token']")

    return tokenTag ? tokenTag.content : null
  }

  get csrfParam() {
    const paramTag = document.querySelector("meta[name='csrf-param']")

    return paramTag ? paramTag.content : null
  }

  camelize(str) {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }
}
