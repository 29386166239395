import ApplicationController from "./application_controller"
import { createPopper } from "@popperjs/core"

export default class extends ApplicationController {
  ARROW_SIZE = 10

  static values = {
    tooltip: String,
    placement: {
      type: String,
      default: "top"
    },
    showOnHover: {
      type: Boolean,
      default: true
    },
    animationDuration: {
      type: Number,
      default: 130
    }
  }

  connect() {
    if (this.element.title) {
      this.tooltipValue = this.element.title
      this.element.removeAttribute("title")
    }

    if (this.showOnHoverValue) {
      this.element.addEventListener("mouseenter", this.show.bind(this))
      this.element.addEventListener("mouseleave", this.hide.bind(this))
    }
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.destroy()
      this.tooltip = null
    }

    this.element.removeEventListener("mouseenter", this.show.bind(this))
    this.element.removeEventListener("mouseleave", this.hide.bind(this))
  }

  show() {
    if (!this.tooltip) this.tooltip = this.buildTooltip()

    const tooltipElement = this.tooltip.state.elements.popper

    tooltipElement.classList.remove("bmx-tooltip--fade-out")
    tooltipElement.classList.add("bmx-tooltip--fade-in")

    this.fadeTimeout = setTimeout(() => {
      tooltipElement.classList.remove("bmx-tooltip--fade-in")
    }, this.animationDurationValue)
  }

  hide() {
    if (!this.tooltip) return

    const tooltipElement = this.tooltip.state.elements.popper

    tooltipElement.classList.remove("bmx-tooltip--fade-in")
    tooltipElement.classList.add("bmx-tooltip--fade-out")

    this.fadeTimeout = setTimeout(() => {
      this.tooltip.destroy()
      tooltipElement.remove()
      this.tooltip = null
    }, this.animationDurationValue)

  }

  buildTooltip() {
    const containerElement = document.createElement("div")
    containerElement.innerHTML = this.tooltipHtml(this.tooltipValue)

    const tooltipElement = containerElement.firstElementChild
    document.body.appendChild(tooltipElement)

    return createPopper(
      this.element,
      tooltipElement,
      {
        placement: this.placementValue,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, this.ARROW_SIZE]
            }
          }
        ]
      }
    )
  }

  tooltipHtml(message) {
    return `
      <div class="bmx-tooltip" style="--animation-duration: ${this.animationDurationValue}ms;">
        <div class="bmx-tooltip__message">
          ${message}
        </div>
        <div data-popper-arrow class="bmx-tooltip__arrow" style="--size: ${this.ARROW_SIZE}px;"></div>
      </div>
    `
  }
}
