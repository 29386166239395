import SelectController from "./select_controller"

export default class extends SelectController {
  connect() {
    if (!this.element.multiple) this.element.multiple = true
    if (this.element.name && !this.element.name.endsWith("[]")) {
      this.element.name += "[]"
    }
    if(!this.selectizeOptions.plugins) this.selectizeOptions.plugins = []
    this.selectizeOptions.plugins.push("remove_button")

    super.connect()
  }
}
