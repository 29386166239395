import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "day",
    "openTime",
    "closeTime",
    "removeButton",
    "destroy"
  ]

  connect() {
    this.initDayControls()
  }

  initDayControls() {
    this.dayTargets.forEach(day => this.syncDayButton(day))
  }

  toggleDay(event) {
    this.syncDayButton(event.target)
  }

  setAllTimes() {
    this.openTimeTarget.value = "12:00AM"
    this.closeTimeTarget.value = "12:00AM"
    this.dayTargets.forEach(day => {
      day.checked = true
      this.syncDayButton(day)
    })
  }

  allTimes() {
    return this.openTimeTarget.value == "12:00AM" &&
      this.closeTimeTarget.value == "12:00AM" &&
      this.dayTargets.every(day => day.checked)
  }

  empty() {
    return this.openTimeTarget.value == "" &&
      this.closeTimeTarget.value == "" &&
      this.dayTargets.every(day => !day.checked)
  }

  syncDayButton(checkbox) {
    checkbox.parentElement.classList.toggle("btn-primary", checkbox.checked)
    checkbox.parentElement.classList.toggle("btn-unselected", !checkbox.checked)
  }

  disable() {
    this.setDisabled(true)
  }

  enable() {
    this.setDisabled(false)
  }

  setDisabled(value) {
    const elements = [...this.dayTargets, this.openTimeTarget, this.closeTimeTarget, this.removeButtonTarget]
    elements.forEach(target => target.disabled = value)
  }

  remove() {
    this.destroyTarget.value = "1"
    this.element.classList.add("hidden")
  }

  removed() {
    return this.destroyTarget.value == "1"
  }
}
