import { Controller } from "stimulus"
import intlTelInput from "intl-tel-input"

export default class extends Controller {
  static values = {
    placeholderNumberType: String,
    countries: Array
  }

  connect() {
    if (!this.telInputInitialized()) {
      this.initTelInput()
    }
  }

  // causes an infinite loop in some cases
  // disconnect() {
  //   if (this.telInput) {
  //     $(this.element).intlTelInput("destroy")
  //     this.telInput = null
  //   }
  // }

  initTelInput() {
    const options = {
      preferredCountries: ["us"],
      onlyCountries: this.countriesValue,
      autoHideDialCode: true,
      separateDialCode: true,
      utilsScript: `https://cdn.jsdelivr.net/npm/intl-tel-input@${intlTelInputGlobals.version}/build/js/utils.js`
    }

    if (this.hasPlaceholderNumberTypeValue) {
      options.placeholderNumberType = this.placeholderNumberTypeValue
    }

    const name = this.element.attributes.name?.value
    if (name) {
      options.hiddenInput = name
      this.element.removeAttribute("name")
    }

    intlTelInput(this.element, options)
  }

  telInputInitialized() {
    return !!intlTelInputGlobals.getInstance(this.element)
  }
}
