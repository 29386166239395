import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static values = {
        url: String
    }

    navigate(event) {
        if (this.hasUrlValue) {
            Turbo.visit(this.urlValue)
        }
    }
}
