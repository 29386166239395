import ApplicationController from "./application_controller"
import { createPopper } from "@popperjs/core"

export default class extends ApplicationController {
  static targets = ["content", "target"]
  static values = {
    placement: String,
    hiddenClass: String,
    arrowPadding: Number
  }

  connect() {
    const id = this.id = Math.random().toString().substr(2, 8)
    document.querySelector("body").addEventListener("bmx:popup:open", (event) => {
      if (event.detail.id !== id) {
        this.hide(event)
      }
    })
  }

  disconnect() {
    if (this.popup) {
      this.popup.destroy()
      this.popup = null
    }
  }

  show(event) {
    let target = event.target || this.element
    if (this.hasTargetTarget) {
      target = this.targetTarget
    }
    if (!this.popup) this.buildPopup(target)

    const myEvent = new CustomEvent("bmx:popup:open", {
      bubbles: true,
      cancelable: true,
      composed: false,
      detail: {
        id: this.id
      }
    })
    document.querySelector("body").dispatchEvent(myEvent)
    this.popup.update()

    if (this.hasHiddenClassValue) {
      this.contentTarget.classList.remove(this.hiddenClassValue)
    }
  }

  buildPopup(target) {
    if (!target) return
    if (!this.hasContentTarget && !this.buildContentTarget()) return

    let placement = "top"
    if (this.hasPlacementValue) {
      placement = this.placementValue
    }

    let arrowPadding = 0
    if (this.hasArrowPaddingValue) arrowPadding = this.arrowPaddingValue

    this.popup = createPopper(
      target,
      this.contentTarget,
      {
        placement: placement,
        modifiers: [
          {
            name: "arrow",
            options: {
              padding: arrowPadding
            }
          },
          {
            name: "offset",
            options: {
              offset: [0, 10]
            }
          }
        ]
      }
    )
  }

  hide(event) {
    if (!this.popup) return
    event.stopPropagation()

    if (this.hasContentTarget && this.hasHiddenClassValue) {
      this.contentTarget.classList.add(this.hiddenClassValue)
    }
  }

  update() {
    if (!this.popup) return
    this.popup.update()
  }

  buildContentTarget() {
    if (!this.element.title) return false

    const content = document.createElement("div")
    content.classList.add("popup-content")
    content.dataset.popupTarget = "content"
    content.innerHTML = this.element.title
    this.element.title = ""

    this.element.appendChild(content)

    return true
  }
}
