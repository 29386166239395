import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  reloadAccessLog(_event) {
    this.accessLogTarget.reload()
  }

  get accessLogTarget() {
    return document.getElementById("key-locker-access-log")
  }
}
