import { Application } from "stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import Autosave from "stimulus-rails-autosave"
import CharacterCounter from "stimulus-character-counter"
import TextareaAutogrow from "stimulus-textarea-autogrow"
import env from "../../shared/env"

window.Stimulus = Application.start()
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })
registerControllers(Stimulus, controllers)

// Documentation:
// https://stimulus-components.netlify.app/docs/components/stimulus-rails-nested-form/
Stimulus.register("autosave", Autosave)
Stimulus.register("character-counter", CharacterCounter)
Stimulus.register("textarea-autogrow", TextareaAutogrow)

if (env === "test") {
  // propagate errors that happen inside Stimulus controllers
  Stimulus.handleError = (error, message, detail) => {
    throw error
  }
}
