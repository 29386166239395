import ApplicationController from "../application_controller"

export default class extends ApplicationController {
    static targets = [ "pinField", "confirmOrSetupSection", "setupPinSection" ]
    static values = {
        compartmentReadyUrl: String,
        createSetupPinUrl: String
    }

    connect() {
    }

    markAsReady(e) {
        e.preventDefault()
        return $.ajax({
            type: "POST",
            url: this.compartmentReadyUrlValue,
            accept: "json",
            dataType: "json",
            xhrFields: {
                withCredentials: true
            },
            success: (_return) => {
                location.reload()
            },
            error: () => { alert("Failed marking compartment as ready") }
        })
    }

    createSetupPin(e) {
        e.preventDefault()

        const controller = this

        return $.ajax({
            type: "POST",
            url: this.createSetupPinUrlValue,
            accept: "json",
            dataType: "json",
            xhrFields: {
                withCredentials: true
            },
            success: (_return) => {
                controller.confirmOrSetupSectionTarget.style.display = "none"
                controller.setupPinSectionTarget.style.display = "block"
                controller.pinFieldTarget.innerText = _return.pin
            },
            error: () => { alert("Failed creating setup pin") }
        })
    }
}
