/* Opens a modal with an embedded Turbo frame that points to the link's HREF
 * NOTE: This controller expects that the element it is attached to has a HREF
 *       attribute!
 */
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    targetFrameId: String,
    stopPropagation: {
      type: Boolean,
      default: false
    },
    closeOnEscape: {
      type: Boolean,
      default: true
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true
    }
  }
  static outlets = [ "modal" ]
  static classes = [ "modalContent", "modal", "wrapper" ]

  connect() {
    this.element.addEventListener("click", this.openModal.bind(this))
  }

  openModal(event) {
    // Add `data-modal-action-prevent-navigation` attribute to prevent modal from opening.
    // You will need this if you want to make link behave as disabled.
    if (event.target.dataset.modalActionPreventNavigation === "true") return

    /*
     * We want to avoid assigning source attribute to turbo-frame as much as possibele and leverage default
     * Turbo behavior instead.
     * Leaving assigning src attribute for backward compatibility.
     * We are pausing request until after modal is inserted into the DOM and then resuming to avoid collisions
     * when response is returned before modal is created (happens in some exotic scenarios like opening page
     * on a smart tv).
     */
    let source = this.targetURI
    const dataset = this.element.dataset

    if (dataset.turboMethod || dataset.turboFrame) {
      source = undefined
      document.addEventListener("turbo:before-fetch-request", (e) => this.pauseRequest(e), {once: true})
    } else {
      event.preventDefault()
      if (this.stopPropagationValue) event.stopPropagation()
    }

    // Create modal and insert it to DOM
    let modalClass = "modal fade"
    let modalContentClass = "modal-content"

    if (this.hasModalClass) modalClass = this.modalClass
    if (this.hasModalContentClass) modalContentClass = this.modalContentClasses.join(" ")
    if (this.hasWrapperClass) modalContentClass = `${modalContentClass} ${this.wrapperClass}`

    let interactionAttributes = ""
    if (!this.closeOnEscapeValue) interactionAttributes += " data-keyboard='false'"
    if (!this.closeOnOutsideClickValue) interactionAttributes += " data-backdrop='static'"

    const modalHTML = `
      <div class="${modalClass}" ${interactionAttributes} tabindex="-1" role="dialog" data-controller="modal">
        <div class="modal-dialog" role="document">
          <div class="${modalContentClass}">
            <turbo-frame id="${this.targetFrameIdValue}" ${source ? "src=\"" + source + "\"" : ""}>
              <div class="flex items-center justify-center h-full min-h-40 w-full">
                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
              </div>
            </turbo-frame>
          </div>
        </div>
      </div>
    `
    const container = document.createElement("div")
    container.innerHTML = modalHTML.trim()
    const modal = container.firstChild
    document.body.appendChild(modal)

    const $modal = $(modal)
    $modal.on("hidden.bs.modal", function(_event) { $modal.remove() })
    $modal.modal("show")
  }

  pauseRequest(event) {
    if (!this.hasModalOutlet) {
      event.preventDefault()
      this.request = event.detail
    }
  }

  modalOutletConnected(outlet, element) {
    if (this.request) this.request.resume()
  }

  get targetURI() {
    return this.element.getAttribute("href") || this.element.getAttribute("src")
  }
}
