import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { imageUrl: String }

  connect() {
    this.element.addEventListener("click", this.openModal.bind(this))
  }

  openModal(event) {
    event.preventDefault()

    const modalHTML = `
      <div class="modal fade" tabindex="-1" role="dialog" data-controller="modal">
        <div class="image-modal" role="document">
          <i class="icon icon-close-image-modal" data-action="click->modal#hide"></i>
          <div class="modal-content">
            <img src="${this.imageUrlValue}" width="600"></img>
          </div>
        </div>
      </div>
    `
    const container = document.createElement("div")
    container.innerHTML = modalHTML.trim()
    const modal = container.firstChild
    document.body.appendChild(modal)

    const $modal = $(modal)
    $modal.on("hidden.bs.modal", function(_event) { $modal.remove() })
    $modal.modal("show")
  }
}
