import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "enableToursCheckbox",
    "enableQrKeyCheckbox",
    "enableAmenityReservationsCheckbox",
    "amenityReservationsFeeStrategy",
    "anonymousNote"
  ]
  static values = {
    tenantPrivacyLegislationAlert: String,
    tenantPhotosActivityFeedAlert: String,
    tenantSendInvitationsAlert: String,
    syncWithCloudKeyzAlert: String,
    syncWithDormakabaAlert: String,
    syncWithYaleAlert: String
  }

  connect() {
    this.toggleAmenitySettings()
  }

  changeTourCheckbox() {
    if (this.enableToursCheckboxTarget.checked && !this.enableQrKeyCheckboxTarget.checked) {
      if (confirm("Self Guided Touring requires virtual keys. Enable Virtual Keys?")) {
        this.enableQrKeyCheckboxTarget.checked = true
      } else {
        this.enableToursCheckboxTarget.checked = false
      }
    }
  }

  showTenantPrivacyLegislationAlert(event) {
    if(event.target.checked == true) {
      if (confirm(this.tenantPrivacyLegislationAlertValue)) {
        event.target.checked = true
      } else {
        event.target.checked = false
      }
    }
  }

  showTenantPhotosActivityFeedAlert(event) {
    if(event.target.checked == false) {
      if (confirm(this.tenantPhotosActivityFeedAlertValue)) {
        event.target.checked = false
      } else {
        event.target.checked = true
      }
    }
  }

  showSendInvitationsAlert(event) {
    if(event.target.checked == false) {
      if (confirm(this.tenantSendInvitationsAlertValue)) {
        event.target.checked = false
      } else {
        event.target.checked = true
      }
    }
  }

  toggleAmenitySettings() {
    if (!this.hasAmenityReservationsFeeStrategyTarget) return
    if (!this.hasEnableAmenityReservationsCheckboxTarget) return

    if (this.enableAmenityReservationsCheckboxTarget.checked == true) {
      this.amenityReservationsFeeStrategyTarget.disabled = false
    } else {
      this.amenityReservationsFeeStrategyTarget.disabled = true
    }
  }

  showSyncWithCloudKeyzAlert(event) {
    if(event.target.checked == true) {
      if (confirm(this.syncWithCloudKeyzAlertValue)) {
        event.target.checked = true
      } else {
        event.target.checked = false
      }
    }
  }

  showSyncWithDormakabaAlert(event) {
    if (event.target.checked) {
      event.target.checked = confirm(this.syncWithDormakabaAlertValue)
    }
  }

  showSyncWithYaleAlert(event) {
    if (event.target.checked) {
      event.target.checked = confirm(this.syncWithYaleAlertValue)
    }
  }

  toggleAnonymousNote(event) {
    this.anonymousNoteTarget.classList.toggle("hidden", event.target.value !== "anonymous")
  }
}
