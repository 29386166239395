import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static outlets = [
    "nested-form"
  ]
  static targets = [
    "labelInput",
    "labelWarning",
    "moveDownButton",
    "moveUpButton",
    "positionInput",
    "actionSelector",
    "tenantSelector",
    "targetDeviceSelector",
    "accessPointSelector",
    "directorySelector",
    "directoryScopePicker",
    "accessGroupIdsPicker",
    "zoneIdsPicker"
  ]
  static values = {
    showTenantSelectorOn: Array,
    showTargetDeviceSelectorOn: Array,
    showAccessPointSelectorOn: Array,
    showDirectorySelectorOn: Array,
    showZonesPickerOn: Array,
    showGroupsPickerOn: Array,
    labelTranslations: Object,
    defaultLabels: Object
  }

  connect() {
    this.selectAction()
  }

  initialize() {
    this.toggleLabelWarning()
    if (this.hasNestedFormOutlet) {
      this.nestedFormOutlet.registerItem(this)
    }
  }

  moveDown(event) {
    this.nestedFormOutlet.moveDown(this.currentPosition - 1)
  }

  moveUp(event) {
    this.nestedFormOutlet.moveUp(this.currentPosition - 1)
  }

  remove(event) {
    this.nestedFormOutlet.removeAndReorder(event, this.currentPosition - 1)
  }

  selectAction() {
    if (!this.hasActionSelectorTarget) return

    const selectedValue = this.actionSelectorTarget.value
    const valuesToTargetMappings = {
      showTenantSelectorOnValue: this.tenantSelectorTarget,
      showTargetDeviceSelectorOnValue: this.targetDeviceSelectorTarget,
      showAccessPointSelectorOnValue: this.accessPointSelectorTarget,
      showDirectorySelectorOnValue: this.directorySelectorTarget
    }

    for (const [valuesKey, target] of Object.entries(valuesToTargetMappings)) {
      if (!target) continue

      target.classList.toggle("hidden", !this[valuesKey].includes(selectedValue))
    }

    this.selectDirectory()
    this.applyTranslationsFor(selectedValue)
    this.setDefaultLabel()
  }

  selectDirectory() {
    if (!this.hasDirectorySelectorTarget) return

    const selectElement = this.directorySelectorTarget.querySelector("select[name$='[directory_scope]']")
    if (!selectElement) return

    const selectedValue = selectElement.value

    const valuesToTargetMappings = {
      showZonesPickerOnValue: this.zoneIdsPickerTarget,
      showGroupsPickerOnValue: this.accessGroupIdsPickerTarget
    }

    for (const [valuesKey, target] of Object.entries(valuesToTargetMappings)) {
      if (!target) continue

      target.classList.toggle("hidden", !this[valuesKey].includes(selectedValue))
    }

    // business directories can only be linked to access groups
    this.directoryScopePickerTarget.classList.toggle("hidden", this.actionSelectorTarget.value === "business_directory")
  }

  toggleLabelWarning() {
    this.labelWarningTarget.classList.toggle("hidden", this.labelInputTarget.value.length <= 15)
  }

  processLabelChange() {
    if (!this.hasLabelInputTarget) return

    this.labelInputTarget.dataset.originalText = this.labelInputTarget.value
    this.labelInputTarget.dataset.presenceError = false
  }

  setDefaultLabel() {
    if (!this.hasLabelInputTarget) return
    const dataset = this.labelInputTarget.dataset

    const originalText = dataset.originalText
    const presenceError = dataset.presenceError == "true"

    // If the user has set the label themselves, don't override it
    if (presenceError || (originalText && originalText.trim() !== "")) return

    this.labelInputTarget.value = this.defaultLabelsValue[this.actionSelectorTarget.value] || ""
  }

  updatePosition(position, count) {
    const newPosition = position + 1

    this.moveUpButtonTarget.classList.toggle("hidden", newPosition == 1)
    this.moveDownButtonTarget.classList.toggle("hidden", newPosition == count)

    this.positionInputTarget.value = newPosition
    this.element.style.order = newPosition
  }

  applyTranslationsFor(type) {
    if (!this.hasLabelTranslationsValue) return

    // Restore original translations
    const labelsWithOriginalText = this.element.querySelectorAll("label[data-original-text]")
    for(const label of labelsWithOriginalText) {
      label.textContent = label.dataset.originalText
      delete label.dataset.originalText
    }

    // Apply new translations
    const translations = this.labelTranslationsValue[type]
    if (!translations) return

    for(const [key, value] of Object.entries(translations)) {
      const target = this[`${key}Target`]
      if (!target) continue

      const label = target.querySelector("label")
      if (!label) continue

      label.dataset.originalText = label.textContent
      label.textContent = value
    }
  }

  get currentPosition() {
    return parseInt(this.positionInputTarget.value)
  }
}
