import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["row", "rowsContainer"]
  static values = { associationFields: String }

  add(e) {
    const newId = this.rowTargets.length + 1
    const newRow = this.associationFieldsValue
      .replace(/_\d+_/g, "_" + newId + "_")
      .replace(/\[\d+\]/g, "[" + newId + "]")

    this.rowsContainerTarget.insertAdjacentHTML("beforeend", newRow)
  }

  remove(e) {
    const selectedRow = this.rowTargets.find(row => row.contains(e.target))
    selectedRow.remove()
  }
}
