import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    $(this.element).selectize({
      allowEmptyOption: true,
      dropdownParent: "body",
      onDropdownOpen: function (dropdown) {
        const value = this.getValue()
        if (!value.length) {
          this.clear()
        }
      }
    })
  }
}
