import ApplicationController from "./application_controller"
import { post } from "@rails/request.js"

export default class extends ApplicationController {
  static targets = ["secret", "copyToClipboard", "revealed", "hidden"]
  static values = {
    object: String,
    id: String,
    tenantId: String
  }

  connect() {
    this.updateRevealed(false)
  }

  toggle(event) {
    if (this.revealed) {
      this.hide()
    } else {
      this.reveal()
    }
  }

  async reveal() {
    if (this.revealed) return

    const secret = await this.fetchSecret()

    this.previousContent = this.secretTarget.innerHTML
    this.secretTarget.innerHTML = ""
    this.secretTarget.textContent = secret
    this.updateRevealed(true)

    if (this.hasCopyToClipboardTarget) {
      this.copyToClipboardTarget.dataset.copyToClipboardMessageValue = secret
    }
  }

  hide() {
    if (!this.revealed) return

    const content = this.previousContent || "● ● ● ● ● ●"
    this.secretTarget.innerHTML = content
    this.updateRevealed(false)

    if (this.hasCopyToClipboardTarget) {
      this.copyToClipboardTarget.dataset.copyToClipboardMessageValue = content
    }
  }

  updateRevealed(revealed) {
    this.revealed = revealed
    this.revealedTargets.forEach(element => element.classList.toggle("hidden", !revealed))
    this.hiddenTargets.forEach(element => element.classList.toggle("hidden", revealed))
  }

  async fetchSecret() {
    try {
      return await this.requestSecret()
    } catch (error) {
      console.log(error)
      return "ERROR"
    }
  }

  async requestSecret() {
    const response = await post(this.endpointUrl, {
      body: JSON.stringify({
        object: this.objectValue,
        id: this.idValue,
        tenant_id: this.tenantIdValue
      }),
      credentials: "include"
    })

    const data = await response.json

    return data.secret
  }

  get endpointUrl() {
    const requestOrigin = location.origin
    const endpoint = "/reveal_secrets"

    return requestOrigin + endpoint
  }
}
