import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "contentFrame" ]
  static values = {
    contentFrameUrl: String
  }

  loadContentFrame(event) {
    if (!this.hasContentFrameUrlValue) return
    if (!this.hasContentFrameTarget) return
    if (!event.target.open) return

    this.contentFrameTarget.src = this.contentFrameUrlValue
  }
}
