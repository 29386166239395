import { Controller } from "@hotwired/stimulus"

// This is a base class for React apps. It is used to load and unload React apps.
export default class ReactAppController extends Controller {

    script
    app
    name

    connect() {
        if (!this.name) {
            console.group("react_app_controller: error during connect")
            console.error("name is not defined")
            console.groupEnd()
            return
        }
        this.init()
    }

    init() {
        this.script = document.createElement("script")
        this.script.src = this.element.getAttribute("data-app-src")
        this.script.type = "module"
        this.script.crossOrigin = "anonymous"
        this.script.addEventListener("load", () => {
            if (!this.renderApp) {
                console.group(`${this.name}: error during renderApp`)
                console.error("renderApp is not defined")
                console.groupEnd()
                return
            }

            this.renderApp()
        })
        document.body.appendChild(this.script)
    }

    disconnect() {
        try {
            this.script.remove()
            this.app.unmount()
        } catch (err) {
            console.group(`${this.name}: error during unmount`)
            console.error(err)
            console.groupEnd()
        }
    }
}
