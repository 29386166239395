import ApplicationController from "./application_controller"

export default class BootstrapTabController extends ApplicationController {
  static targets = ["scheduled", "pending", "completed"]

  connect() {
    if (localStorage.getItem("scheduledTourFilterTab") === null)
        {return}

    BootstrapTabController.targets.forEach(element => {
      if (localStorage.getItem("scheduledTourFilterTab") === element)
        // We need to convert the target to a Jquery object because the tab method from bootstrap depends on JQ
        {$(this[`${element}Target`]).children("a").tab("show")}
    })
  }

  storeActiveTab(event) {
    localStorage.setItem("scheduledTourFilterTab", event.currentTarget.dataset.bootstrapTabTarget)
  }
}
