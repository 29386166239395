import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "picker",
    "timeConstraintFields",
    "timeRestrictionFields"
  ]

  static classes = [ "hidden" ]

  connect() {
    this.showSelectedFields()
  }

  showSelectedFields() {
    const selectedOption = this.pickerTarget.value

    if (selectedOption === "TimeConstraint") {
      this.showFields(this.timeConstraintFieldsTargets)
      this.hideFields(this.timeRestrictionFieldsTargets)
    } else if (selectedOption === "TimeRestriction") {
      this.hideFields(this.timeConstraintFieldsTargets)
      this.showFields(this.timeRestrictionFieldsTargets)
    } else {
      this.hideFields(this.timeConstraintFieldsTargets)
      this.hideFields(this.timeRestrictionFieldsTargets)
    }
  }

  hideFields(fields) {
    fields.forEach((field) => {
      field.classList.add(this.hiddenClass)

      field.querySelectorAll("input,select").forEach((input) => {
        input.dataset.wasDisabled = input.disabled
        input.disabled = true
      })
    })
  }

  showFields(fields) {
    fields.forEach((field) => {
      field.classList.remove(this.hiddenClass)

      field.querySelectorAll("input,select").forEach((input) => {
        input.disabled = (input.dataset.wasDisabled === "true")
        if (input.selectize) input.selectize.enable()
      })
    })
  }
}
