import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  FILE_BASENAME_REGEX = /(\.[^.]+)$/
  HUMANIZE_STRING_REGEX = /[._'"%^=+]/

  static targets = [
    "displayNameInput", "fileNameLabel", "fileInput", "fileInputCache",
    "deleteButton"
  ]

  static values = {
    hasCachedFile: Boolean
  }

  connect() {
    this.promptUserForFile()
    if (!this.hasFile && !this.hasCachedFile) this.hide()
  }

  promptUserForFile() {
    if (!this.hasFileInputTarget) return
    if (this.hasFile || this.hasCachedFile) return

    const event = document.createEvent("MouseEvents")
    event.initEvent("click", true, false)
    this.fileInputTarget.dispatchEvent(event)
  }

  deleteIfFileBlank() {
    if (this.hasFile) {
      this.show()
    }
    else if (this.hasFileInputTarget && this.fileInputTarget.dataset.pendingReselect === "true") {
      return
    }
    else {
      this.delete()
    }
  }

  hide() {
    this.element.classList.add("hidden")
  }

  show() {
    this.element.classList.remove("hidden")
  }

  delete() {
    if (!this.hasDeleteButtonTarget) return

    const event = document.createEvent("MouseEvents")
    event.initEvent("click", true, false)
    this.deleteButtonTarget.dispatchEvent(event)
  }

  infereDisplayNameFromFile(event) {
    event.preventDefault()

    if (!this.hasFile || !this.hasDisplayNameInputTarget) return

    let name = this.file.name
    name = name.replace(this.FILE_BASENAME_REGEX, "")
    name = name.replace(this.HUMANIZE_STRING_REGEX, "")

    this.displayNameInputTarget.value = name
  }

  displayFileName(event) {
    event.preventDefault()

    if (!this.file || !this.hasFileNameLabelTarget) return

    this.fileNameLabelTarget.innerText = this.file.name
  }

  get file() {
    if (!this.hasFileInputTarget) return null

    const files = this.fileInputTarget.files
    if (!files) return null

    const file = files[0]
    if (!file) return null

    return file
  }

  get hasFile() {
    return !!this.file
  }

  get hasCachedFile() {
    const target = this.hasFileInputCacheTarget

    if (this.hasHasCachedFileValue && this.hasCachedFileValue) return true

    if (!target || !target.value || target.value === "") return false

    return true
  }
}
