import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  connect() {
    $(".mini-door-release:first").addClass("active")

    $(".mini-door-release").on("click", function() {
        var img = $(".medium-door-release img")

        img.attr("src", $(this).data("medium-url"))
        img.attr("title", $(this).attr("title"))

        $(".mini-door-release").removeClass("active")

        $(this).addClass("active")
    })
  }
}

