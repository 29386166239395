import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["searchForm", "tourURL", "alert"]

  clearForm() {
    let cleared = false
    for (const element of this.searchFormTarget.elements) {
      if (element.type === "text") {
        if (element.value) {
          element.value = ""
          cleared = true
        }
      }

    }
    if (cleared) {
      this.searchFormTarget.submit()
    }
  }

  clickTab(event) {
    this.clearForm()

  }

  copyTourURL(event) {
    const el = document.createElement("textarea")
    el.value = this.tourURLTarget.innerText
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
    this.alertTarget.style.opacity = "100%"
    setTimeout( () => {
      this.alertTarget.style.opacity = "0"
    }, 2000)
  }
}
