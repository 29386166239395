import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["selected"]
  static values = {
    confirmationModalTitle: String,
    confirmationModalBody: String,
    confirmationModalPrimaryAction: String
  }

  allowUnitAccess(event) {
    const confirmed = !!Rails.confirm({
      title: this.confirmationModalTitleValue,
      body: this.confirmationModalBodyValue,
      confirm: this.confirmationModalPrimaryActionValue
    }, event.target)
    if (confirmed) {
      event.target.form.requestSubmit()
    }
  }

  submitForm(event) {
    const externalDevicesPresent = Array.from(this.selectedTarget.options).some(option =>
      option.text.includes("Unit RemoteLock") || option.text.includes("Unit SmartLock")
    )
    if (externalDevicesPresent) {
      event.preventDefault()
      this.allowUnitAccess(event)
    }
  }
}
