import ApplicationController from "../application_controller"
import elementCSSSelector from "../../utils/element_css_selector"
import { useDebounce } from "stimulus-use"

export default class extends ApplicationController {
  static targets = [
    "amenitySelect",
    "maximumOccupancy",
    "overrideReservationFeeCheckbox",
    "reservationFeeInput"
  ]
  static values = {
    configurationUrl: String,
    configurationMethod: String,
    debounceInterval: Number
  }
  static debounces = [ "configure" ]

  connect() {
    if (this.hasDebounceIntervalValue) {
      useDebounce(this, { wait: this.debounceIntervalValue })
    }

    this.restoreActiveElement()
  }

  configure(event) {
    if (!this.hasConfigurationUrlValue) return
    if (!this.hasConfigurationMethodValue) return
    if (!window._rails_loaded) return

    this.enterLoadingState()

    this.element.method = this.configurationMethodValue
    this.element.action = this.configurationUrlValue

    const methodInput = this.element.querySelector("input[name=\"_method\"]")
    if (methodInput) methodInput.value = this.configurationMethodValue

    if (this.hasAmenitySelectTarget && this.amenitySelectTarget === event.target) {
      this.maximumOccupancyTarget.setAttribute("name", `${this.maximumOccupancyTarget.name}_deleted`)
    }

    Rails.fire(this.element, "submit")
  }

  enterLoadingState() {
    this.element.dataset.reservationFormControllerLoading = true
    const loadingOverlay = document.createElement("DIV")
    loadingOverlay.classList.add("modal-form-container__loading-overlay")
    this.element.appendChild(loadingOverlay)
  }

  toggleReservationFeeOverride() {
    if (!this.hasOverrideReservationFeeCheckboxTarget) return

    let action = "disabled"
    if (this.overrideReservationFeeCheckboxTarget.checked) action = "enabled"

    this.toggleReservationFeeInput(action)
  }

  toggleReservationFeeInput(disabled) {
    if (!this.hasReservationFeeInputTarget) return

    if (disabled === "disabled") disabled = true
    else disabled = false

    this.reservationFeeInputTarget.disabled = disabled

    if (this.hasOverrideReservationFeeCheckboxTarget) {
      this.overrideReservationFeeCheckboxTarget.checked = !disabled
    }

    if (disabled) this.recalculateFee()
  }

  storeActiveElement(event) {
    event.stopPropagation()

    const element = event.target
    if (!element) return

    const selector = elementCSSSelector(element).replaceAll(".active", "")
    localStorage.setItem(this.activeElementStorageKey, selector)
  }

  restoreActiveElement() {
    const activeElementSelector = localStorage.getItem(this.activeElementStorageKey)
    localStorage.removeItem(this.activeElementStorageKey)

    if (!activeElementSelector || activeElementSelector === "") return

    setTimeout(function() {
      const element = document.querySelector(activeElementSelector)
      if (!element) return

      const event = document.createEvent("MouseEvents")
      event.initEvent("click", true, true)
      element.dispatchEvent(event)
    }, 1)
  }

  clearActiveElement() {
    localStorage.removeItem(this.activeElementStorageKey)
  }

  get activeElementStorageKey() {
    return [
      "amenity--reservation-form",
      this.element.id,
      "active-element-selector"
    ].join("_")
  }
}
