import ApplicationController from "./application_controller"
import { patch } from "@rails/request.js"

export default class extends ApplicationController {
  static values = {
    url: String
  }

  refreshPanelActions() {
    patch(this.urlValue, {
      body: new FormData(this.element.closest("form")),
      responseKind: "turbo-stream"
    }).then((response) => {
      if (!response.ok) {
        alert(response.response.statusText)
      }
    })
  }
}
