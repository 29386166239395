import ApplicationController from "./application_controller"
import parentDatasetSearch from "../utils/parent_dataset_search"

export default class extends ApplicationController {
  static values = {
    destinationUrl: String,
    target: String,
  }

  navigateToDestinationUrl(event) {
    if(this.shouldPreventNavigation(event.target)) return

    if (this.hasTargetValue) {
      window.open(this.destinationUrlValue, this.targetValue)
    } else {
      Turbo.visit(this.destinationUrlValue)
    }
  }

  shouldPreventNavigation(element) {
    return parentDatasetSearch(event.target, "preventNavigation")
  }
}
