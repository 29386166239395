import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["unitsDropdown"]

  connect() {
    // force selectize to load all items in DOM
    document.querySelectorAll(".selectize-inside-overflow-block").forEach(item => {
      if(item.selectize != undefined) {
        item.selectize.refreshOptions(false)
      }
    })

    this.disableOccupiedUnits()
  }

  disableOccupiedUnits() {
    this.resetAllDropdownOptions()

    const occupied_units = this.findSelectedUnits()

    if(occupied_units.length > 0) {
      occupied_units.forEach(unit => {
        this.disableUnit(unit)
      })
    }
  }

  resetAllDropdownOptions() {
    document.querySelectorAll(".selectize-dropdown-content .option").forEach(item => item.setAttribute("data-selectable", true))
  }

  findSelectedUnits() {
    return this.unitsDropdownTargets.map(item => { return item.value } )
  }

  disableUnit(unit_id) {
    document.querySelectorAll(`.selectize-dropdown-content [data-value='${unit_id}']`).forEach(item => {
      if(item.dataset.value != "") {
        item.removeAttribute("data-selectable")
      }
    })
  }
}
