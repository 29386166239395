import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "compartment" ]
  static values = {
    refreshInterval: {
      type: Number,
      default: 10 * 60 * 1000
    }
  }

  selectCompartment(event) {
    // event.preventDefault()

    const compartment = event.target.closest(".key-locker-compartment")
    const metaKeyPressed = event.ctrlKey || event.metaKey
    const shiftKeyPressed = event.shiftKey
    const compartmentSelected = compartment.classList.contains("key-locker-compartment--selected")
    const selectedCompartmentsCount = Number(this.compartmentTargets.reduce((n, x) => n + (x.classList.contains("key-locker-compartment--selected")), 0))

    if (!metaKeyPressed && !shiftKeyPressed && selectedCompartmentsCount <= 1) {
      this.clearSelection()
      compartmentSelected ? compartment.classList.remove("key-locker-compartment--selected") : compartment.classList.add("key-locker-compartment--selected")
    } else if (!metaKeyPressed && !shiftKeyPressed && selectedCompartmentsCount > 1){
      this.clearSelection()
      compartment.classList.add("key-locker-compartment--selected")
    }

    if (metaKeyPressed && !compartmentSelected) {
      compartment.classList.add("key-locker-compartment--selected")
    } else if (metaKeyPressed && compartmentSelected) {
      compartment.classList.remove("key-locker-compartment--selected")
    }

    if (shiftKeyPressed && this.lastSelectedCompartment?.classList.contains("key-locker-compartment--selected")) {
      const prevSelectedIndex = this.compartmentTargets.indexOf(this.lastSelectedCompartment)
      const currentSelectionIndex = this.compartmentTargets.indexOf(compartment)

      if (prevSelectedIndex < currentSelectionIndex) {
        for (let i = 0; i < this.compartmentTargets.length; i++) {
          if (i >= prevSelectedIndex && i <= currentSelectionIndex) {
            if (!compartmentSelected) {
              this.compartmentTargets[i].classList.add("key-locker-compartment--selected")
            } else {
              this.compartmentTargets[i-1].classList.remove("key-locker-compartment--selected")
            }
          }
        }
      } else {
        for (let i = this.compartmentTargets.length - 1; i >= 0; --i) {
          if (i <= prevSelectedIndex && i >= currentSelectionIndex) {
            if (!compartmentSelected) {
              this.compartmentTargets[i].classList.add("key-locker-compartment--selected")
            } else {
              this.compartmentTargets[i+1].classList.remove("key-locker-compartment--selected")
            }
          }
        }
      }
    }

    this.lastSelectedCompartment = compartment

    const url = this.getSelected(compartment.dataset.link, compartment.dataset.id)
    const turboFrame = document.getElementById(compartment.dataset.turboFrame)
    if (turboFrame) turboFrame.src = url

    this.clearFilters()
  }

  clearSelection(event) {
    this.compartmentTargets.forEach(target => {
      target.classList.remove("key-locker-compartment--selected")
    })

    this.clearFilters()
  }

  clearFilters() {
    const filters = document.querySelectorAll(".filter-label--key_locker_filter-default,.filter-label--key_locker_filter-available")
    for (let i = 0; i < filters.length; i++) {
      filters[i].classList.remove("filter-label--key_locker_filter-selected")
    }
  }

  deselectUseCase(useCase) {
    const compartments = document.querySelectorAll(`[data-use-case='${useCase}']`)
    for (let i = 0; i < compartments.length; i++) {
      compartments[i].classList.remove("key-locker-compartment--selected")
    }
  }

  selectUseCase(useCase) {
    const compartments = document.querySelectorAll(`[data-use-case='${useCase}']`)
    for (let i = 0; i < compartments.length; i++) {
      compartments[i].classList.add("key-locker-compartment--selected")
    }
  }

  select(event) {
    if (event.target.classList.contains("filter-label--key_locker_filter-selected")) {
      event.target.classList.remove("filter-label--key_locker_filter-selected")
      document.querySelector(".filter-label--key_locker_filter-all").classList.remove("filter-label--key_locker_filter-selected")
      this.deselectUseCase(event.target.dataset.selectUseCase)
    } else if (document.querySelectorAll(".filter-label--key_locker_filter-selected").length > 0) {
      event.target.classList.add("filter-label--key_locker_filter-selected")
      this.selectUseCase(event.target.dataset.selectUseCase)
    } else {
      this.clearSelection()
      event.target.classList.add("filter-label--key_locker_filter-selected")
      this.selectUseCase(event.target.dataset.selectUseCase)
    }

    event.target.closest("a").href = this.getSelected(event.target.closest("a").dataset.link, 0)
  }

  selectStatus(event) {
    const compartments = document.querySelectorAll(`[data-status='${event.target.dataset.selectStatus}']`)
    if (event.target.classList.contains("filter-label--key_locker_filter-selected")) {
      event.target.classList.remove("filter-label--key_locker_filter-selected")
      for (let i = 0; i < compartments.length; i++) {
        compartments[i].classList.remove("key-locker-compartment--selected")
      }
    } else if (document.querySelectorAll(".filter-label--key_locker_filter-selected").length > 0) {
      event.target.classList.add("filter-label--key_locker_filter-selected")
      for (let i = 0; i < compartments.length; i++) {
        compartments[i].classList.add("key-locker-compartment--selected")
      }
    } else {
      this.clearSelection()
      event.target.classList.add("filter-label--key_locker_filter-selected")
      for (let i = 0; i < compartments.length; i++) {
        compartments[i].classList.add("key-locker-compartment--selected")
      }
    }

    event.target.closest("a").href = this.getSelected(event.target.closest("a").dataset.link, 0)
  }

  selectAll(event) {
    const filters = document.querySelectorAll(".filter-label")

    if (event.target.classList.contains("filter-label--key_locker_filter-selected")) {
      for (let i = 0; i < filters.length; i++) {
        filters[i].classList.remove("filter-label--key_locker_filter-selected")
      }
      this.compartmentTargets.forEach( compartment => {
        compartment.classList.remove("key-locker-compartment--selected")
      })
    } else {
      for (let i = 0; i < filters.length; i++) {
        filters[i].classList.add("filter-label--key_locker_filter-selected")
      }
      this.compartmentTargets.forEach( compartment => {
        compartment.classList.add("key-locker-compartment--selected")
      })
    }

    event.target.closest("a").href = this.getSelected(event.target.closest("a").dataset.link, 0)
  }

  getSelected(url,id) {
    let newUrl

    const selected = document.getElementsByClassName("key-locker-compartment--selected")

    if (selected.length === 0) {
      newUrl = url.replace(`compartments/${id}`, "compartments/0")
    } else {
      const ids = []
      for (let i = 0; i < selected.length; i++) {
        ids.push(selected[i].dataset.id)
      }
      newUrl = url.replace(`compartments/${id}`, `compartments/${ids.join(",")}`)
    }

    return newUrl
  }
}
