import ApplicationController from "./application_controller"
import JsonUtils from "../../shared/json-utils"

export default class extends ApplicationController {
  static targets = ["modal", "body", "title", "confirm"]

  connect () {
    this.$customConfirmationModal = $(this.modalTarget)

    const controller = this
    Rails.confirm = (message, el) => {

      const value = JsonUtils.jsonOrString(message)
      controller.titleTarget.innerText = controller.titleTarget.dataset.title
      if (value.constructor === Object) {
        controller.titleTarget.innerText = value.title
        controller.bodyTarget.innerText = value.body
        if (value.hasOwnProperty("confirm") ) {
          controller.confirmTargets.forEach((element) => element.innerText = value.confirm)
        }
      } else {
        controller.bodyTarget.innerText = value
      }

      this.$customConfirmationModal.modal("show")

      this.lastClicked = el

      return false
    }
    this.overridenConfirm = Rails.confirm
  }

  skipConfirm () {
    Rails.confirm = () => true
  }

  restoreConfirm () {
    Rails.confirm = this.overridenConfirm
  }

  continue () {
    this.$customConfirmationModal.modal("hide")
    this.proceed()
  }

  hideDialog (event) {
    event.preventDefault()
    this.$customConfirmationModal.modal("hide")
  }

  proceed() {
    this.skipConfirm()
    this.lastClicked.click()
    this.restoreConfirm()
  }
}
