import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "role",
    "buildingAdmin", "buildingAdminSelect",
    "companyAdmin", "companyAdminSelect"
  ]

  connect() {
    if (this.hasRoleTarget) {
      this.expand()
    }
  }

  expand() {
    ["building_admin", "company_admin"].forEach(role => {
      if (this.roleTarget.value == role) {
        this[`${this.camelize(role)}SelectTarget`].selectize?.enable()
        this[`${this.camelize(role)}Target`].classList.remove("hidden")
      } else {
        this[`${this.camelize(role)}SelectTarget`].selectize?.disable()
        this[`${this.camelize(role)}Target`].classList.add("hidden")
      }
    })
  }
}
