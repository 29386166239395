import ApplicationController from "../application_controller"

export default class extends ApplicationController {
    static targets = [ "sharedKey", "unitSelect", "tourSection","useCaseSelect", "unitSelectSection",
    "missingKeyDismissed", "markedAsReady", "form", "submitGeneratePin", "addUnitLink"]

    static values = { count: Number }

    connect() {
        this.configure()
    }

    configure() {
        if (this.hasTourSectionTarget) {
            this.tourSectionTarget.style.display = "none"
            this.unitSelectSectionTarget.style.display = "none"
            this.addUnitLinkTarget.style.display = "none"

            if (this.useCaseSelectTarget.selectedOptions[0].value === "tour") {
                this.tourSectionTarget.style.display = "block"
                this.unitSelectSectionTarget.style.display = "block"
                this.addUnitLinkTarget.style.display = "block"
            }
            if (this.useCaseSelectTarget.selectedOptions[0].value === "resident") {
                this.unitSelectSectionTarget.style.display = "block"
            }
            if (this.countValue > 1) {
                this.unitSelectSectionTarget.disabled = true
            }
        }
    }

    dismissNotSetup(event) {
        if (Rails.confirm({
            title: "Dismiss “Action Needed” Status",
            body: "To dismiss “Action Needed” status, please confirm you have the right key in the compartment. You are responsible for making sure your guests have access to the key at arrival.",
            confirm: "Confirm"
            }, event.target)) {
            this.markedAsReadyTarget.value = new Date().toISOString()
            Rails.fire(this.formTarget, "submit")
        }
    }

    dismissMissingKey(event) {
        if (Rails.confirm({
            title: "Dismiss “Missing Key” Status",
            body: "After dismissal, there will be no further alerts or notifications related to this missing item, and this \"Missing Key\" alert will be removed. This action cannot be undone.  To continue and dismiss this \"Missing Key\" status, please confirm that you are OK with this.",
            confirm: "Confirm"
        }, event.target)) {
            this.missingKeyDismissedTarget.value = new Date().toISOString()
            Rails.fire(this.formTarget, "submit")
        }
    }

    confirmOpenAll(event) {
        this.confirmOpenSet(event, event.target.dataset.totalCompartments)
    }

    confirmOpenSet(event, numberSelected) {
        event.preventDefault()

        let body = `This will open the physical door of ${numberSelected} compartments on the KeyLocker device.`

        if (numberSelected == event.target.dataset.totalCompartments) {
            numberSelected = "ALL"
            body = "This will open the physical door of ALL compartments on the KeyLocker device. If misused or left unattended this may lead to theft of the compartment's contents. Please use this action with caution and supervision of the KeyLocker device."

        }

        const confirm = `Open ${numberSelected}`
        if (Rails.confirm({
            title: `Open ${numberSelected} compartments`,
            body: body,
            confirm: confirm
        }, event.target)) {
            $.ajax({
                type: "POST",
                url: event.target.dataset.confirmLink,
                xhrFields: {
                    withCredentials: true
                },
                success: (_response) => {
                    alert("success")
                },
                error: (_response) => { alert("open failed") }
            })
        }
    }
    confirmOpen(event) {
        const selected = document.getElementsByClassName("key-locker-compartment--selected")
        const numberSelected = selected.length

        this.confirmOpenSet(event, numberSelected)
    }


    confirmGeneratePinForAllCompartments(event, numberSelected) {
        let body = `This will generate Setup PIN for ${numberSelected} compartments on the KeyLocker device.`

        if (numberSelected == event.target.dataset.totalCompartments) {
            body = "This action will generate a new Setup PIN for ALL compartments in the KeyLocker device. This new Setup PIN will be displayed on the Compartments table in place of any existing PINs until it is used for each compartment, or has expired.  This will not overwrite any existing valid PINs.  Existing PINs can continue be used until they have expired."
        }

        const confirm = "Generate"
        const controller = this

        if (Rails.confirm({
            title: "Generate Setup PIN",
            body: body,
            confirm: confirm
        }, event.target)) {
            controller.createSetupPin()
        }
    }

    confirmGeneratePin(event) {
        event.preventDefault()
        const selectedCompartments = document.getElementsByClassName("key-locker-compartment--selected")
        const selectedCompartmentsCount = selectedCompartments.length

        if (selectedCompartmentsCount == event.target.dataset.allCompartmentsCount) {
            this.confirmGeneratePinForAllCompartments(event, selectedCompartmentsCount)
        } else {
            this.createSetupPin()
        }
    }

    createSetupPin() {
       this.submitGeneratePinTarget.click()
    }
}
