import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = ["searchForm"]

    clearForm() {
        for (const element of this.searchFormTarget.elements) {
            if (element.type !== "submit") {
                if (element.value) {
                    element.value = ""
                }
            }
            if (element.classList.value === "form-control selectized") {
                element.selectize.clear()
            }
        }
    }
}
