import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static outlets = ["permission-group-form"]
  static values = {
    url: String,
  }

  csvImportSelect(event) {
    const reader = new FileReader()
    const controller = this
    reader.onload = (function (reader) {
      return function () {
        controller.csvImport(reader.result)
        event.target.value = ""
      }
    })(reader)
    reader.readAsText(event.target.files[0])
  }

  csvImport(contents) {
    if (!this.hasUrlValue) return console.log("url not found")

    const lines = contents.split("\n")
    const device_id_index = lines[0].split(",").indexOf("device_id")

    if (device_id_index !== 0) {
      console.log("device_id not found in header")
      return
    }

    const deviceIds = lines.slice(1)
      .map(line => line.split(",")[device_id_index].trim())
      .filter(deviceId => deviceId !== "")

    console.log("Device IDs:", deviceIds)

    if (deviceIds.length === 0) return

    fetch(this.urlValue, {
      method: "POST",
      credentials: "include",
      redirect: "follow",
      headers: {
        "X-CSRF-Token": this.csrfToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ids: deviceIds
      }),
    })
      .then(response => response.json())
      .then(data => this.addAttachments(data))
      .catch(error => this.showError(error))
  }
  addAttachments(devices) {
    devices.forEach(devices => {
      setTimeout(() => {this.addAttachment(devices) }, 0)
    })
  }

  addAttachment(device) {
    if (!this.hasPermissionGroupFormOutlet) return console.log("PermissionGroupFormOutlet not found")
    const element = this.permissionGroupFormOutlet.addAttachment()
    element.dataset["permissionGroupForm-AttachmentSelectedDeviceValue"] = JSON.stringify(device)
  }

  showError(error) {
    console.error("Error importing devices:", error)
  }

  closeModal() {
    $("#importDevicesModal").modal("hide")
  }
}
