import { Turbo } from "@hotwired/turbo-rails"

// show progress bar for turbo-frames load, should be removed when supported by Turbo
// add data-turbo-progress-bar="true" to the turbo frame which needs to display the progress bar
// https://github.com/hotwired/turbo/issues/540

const adapter = Turbo.navigator.delegate.adapter
const session = Turbo.session

let progressBarTimeout = null
document.addEventListener("turbo:before-fetch-request", (event) => {
  const target = event.target
  if (!(target instanceof HTMLElement)) {
    return
  }

  if ("true" === target.getAttribute("data-turbo-progress-bar")) {
    adapter.progressBar.setValue(0)
    progressBarTimeout = window.setTimeout(adapter.showProgressBar, session.progressBarDelay)
  }
})

document.addEventListener("turbo:before-fetch-response", () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout)
    progressBarTimeout = null
  }
})
