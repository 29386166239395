import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    updatePath: String,
    paramsKey: String
  }

  toggleAlertTenant(event) {
    Turbo.clearCache()
    this.sendRequest({ alert_tenant: event.target.checked ? 1 : 0 })
  }

  sendRequest(params) {
    $.ajax({
      url: this.updatePathValue,
      data: {
        [this.paramsKeyValue]: params
      },
      type: "PUT",
      dataType: "script"
    })
  }
}
