import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "hideTarget", "showTarget" ]
  static values = {
    removeClass: String
  }

  connect() {
    if (!this.hasRemoveClassValue) return
    const klass = this.removeClassValue

    let showTargets = [this.element]
    if (this.hasShowTargetTarget) {
      showTargets = this.showTargetTargets
    }

    showTargets.forEach(
      (element) => element.classList.remove(klass)
    )

    if (!this.hasHideTargetTarget) return

    this.hideTargetTargets.forEach(
      (element) => element.classList.add(klass)
    )
  }
}
