import ApplicationController from "./application_controller"

// the current 'tooltip' controller doesn't properly center the arrow
export default class extends ApplicationController {
  static values = {
    placement: { type: String, default: "right" },
    html: Boolean
  }

  connect() {
    $(this.element).tooltip({
      placement: this.placementValue,
      container: "body",
      html: this.htmlValue
    })
  }
}
