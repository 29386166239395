import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "startTime",
    "endTime",
    "activeAllDayEveryDayCheckbox",
    "daySelect",
    "destroyCheckbox",
    "monDayButton",
    "tueDayButton",
    "wedDayButton",
    "thuDayButton",
    "friDayButton",
    "satDayButton",
    "sunDayButton"
  ]

  static values = {
    buttonSelectedClass: String,
    buttonUnselectedClass: String
  }

  BUTTON_SELECTED_CLASS = "btn-primary"
  BUTTON_UNSELECTED_CLASS = "btn-unselected"

  connect() {
    this.dayTargets.forEach((targetName) => {
      const capitalizedTargetName = targetName.charAt(0).toUpperCase() + targetName.slice(1)
      const value = targetName.slice(0, 3)

      if (!this[`has${capitalizedTargetName}Target`]) return
      const target = this[`${targetName}Target`]

      this.setDaySelection(target, this.getValueSelected(value))
    })
  }

  remove(_event) {
    this.setDaySelection(this.monDayButtonTarget, false)
    this.setDaySelection(this.tueDayButtonTarget, false)
    this.setDaySelection(this.wedDayButtonTarget, false)
    this.setDaySelection(this.thuDayButtonTarget, false)
    this.setDaySelection(this.friDayButtonTarget, false)
    this.setDaySelection(this.satDayButtonTarget, false)
    this.setDaySelection(this.sunDayButtonTarget, false)
    if (this.hasStartTimeTarget) this.startTimeTarget.value = null
    if (this.hasEndTimeTarget) this.endTimeTarget.value = null
  }

  setActiveAllDayEveryDay(event) {
    if (!this.hasActiveAllDayEveryDayCheckboxTarget) return
    const selected = this.activeAllDayEveryDayCheckboxTarget.checked

    this.dayTargets.forEach((targetName) => {
      const capitalizedTargetName = targetName.charAt(0).toUpperCase() + targetName.slice(1)

      if (!this[`has${capitalizedTargetName}Target`]) return
      const target = this[`${targetName}Target`]

      this.setDaySelection(target, selected)
    })

    if (selected && this.hasStartTimeTarget) {
      this.setTime(this.startTimeTarget, "00:00")
    }

    if (selected && this.hasEndTimeTarget) {
      this.setTime(this.endTimeTarget, "00:00")
    }
  }

  toggleDay(event) {
    if (!event.target) return
    const targetName = event.target.dataset.timeRestrictionFormTarget
    const capitalizedTargetName = targetName.charAt(0).toUpperCase() + targetName.slice(1)

    if (!this[`has${capitalizedTargetName}Target`]) return
    const target = this[`${targetName}Target`]

    this.setDaySelection(target, !this.buttonIsActive(target))
  }

  setDaySelection(target, selected) {
    if (!target) return

    const targetName = target.dataset.timeRestrictionFormTarget
    const value = targetName.slice(0, 3)

    this.setDayButtonSelected(target, selected)
    this.setValueSelected(value, selected)
    this.checkActiveAllDayEveryDay()
    this.checkShouldDestroy()
  }

  setDayButtonSelected(target, selected) {
    if (!target) return

    if (selected) {
      target.classList.add(this.buttonSelectedClass)
      target.classList.remove(this.buttonUnselectedClass)
    }
    else {
      target.classList.remove(this.buttonSelectedClass)
      target.classList.add(this.buttonUnselectedClass)
    }
  }

  buttonIsActive(target) {
    if (!target) return

    return target.classList.contains(this.buttonSelectedClass)
  }

  get buttonSelectedClass() {
    if (this.hasButtonSelectedClassValue) return this.buttonSelectedClassValue

    return this.BUTTON_SELECTED_CLASS
  }

  get buttonUnselectedClass() {
    if (this.hasButtonUnselectedClassValue) return this.buttonUnselectedClassValue

    return this.BUTTON_UNSELECTED_CLASS
  }

  get dayTargets() {
    return [
      "monDayButton",
      "tueDayButton",
      "wedDayButton",
      "thuDayButton",
      "friDayButton",
      "satDayButton",
      "sunDayButton"
    ]
  }

  setValueSelected(value, selected) {
    if (!this.hasDaySelectTarget) return

    const targets = this.daySelectTarget.querySelectorAll(`[value=${value}]`)

    targets.forEach((node) => {
      node.selected = selected
    })
  }

  getValueSelected(value) {
    if (!this.hasDaySelectTarget) return

    const targets = this.daySelectTarget.querySelectorAll(`[value=${value}]`)

    let selected = true

    targets.forEach((node) => {
      selected = selected && node.selected
    })

    return selected
  }

  checkShouldDestroy() {
    if (!this.hasDestroyCheckboxTarget) return

    this.destroyCheckboxTarget.checked = this.allDaysDeselected
  }

  checkActiveAllDayEveryDay() {
    if (!this.hasActiveAllDayEveryDayCheckboxTarget) return

    this.activeAllDayEveryDayCheckboxTarget.checked =
      this.shouldBeActiveAllDayEveryDay
  }

  get shouldBeActiveAllDayEveryDay() {
    return this.allDaysSelected && this.startsAtMidnight && this.endsAtMidnight
  }

  get allDaysSelected() {
    let result = true

    this.dayTargets.forEach((targetName) => {
      const capitalizedTargetName = targetName.charAt(0).toUpperCase() + targetName.slice(1)

      if (!this[`has${capitalizedTargetName}Target`]) return
      const target = this[`${targetName}Target`]

      result = result && this.buttonIsActive(target)
    })

    return result
  }

  get allDaysDeselected() {
    let result = true

    this.dayTargets.forEach((targetName) => {
      const capitalizedTargetName = targetName.charAt(0).toUpperCase() + targetName.slice(1)
      if (!this[`has${capitalizedTargetName}Target`]) return
      const target = this[`${targetName}Target`]

      result = result && !this.buttonIsActive(target)
    })

    return result
  }

  get startsAtMidnight() {
    if (!this.hasStartTimeTarget) return false

    const value = this.getTime(this.startTimeTarget)

    return this.isMidnight(value)
  }

  get endsAtMidnight() {
    if (!this.hasEndTimeTarget) return false

    const value = this.getTime(this.endTimeTarget)

    return this.isMidnight(value)
  }

  setTime(target, value) {
    if (!target || !value) return

    if (target._flatpickr) {
      const flatpickr = target._flatpickr
      flatpickr.setDate(value, true)
    }
    else {
      target.value = value
    }
  }

  getTime(target) {
    if (!target) return

    const flatpickr = target._flatpickr

    if (flatpickr && flatpickr.selectedDates && flatpickr.selectedDates.length !== 0) {
      return flatpickr.selectedDates[0]
    }
    else {
      return target.value
    }
  }

  isMidnight(value) {
    if (typeof(value) === "string") {
      value = value.toLowerCase()
      const regex = /\s*(0+:0+|12:0+\s*am)\s*/g
      return value.match(regex)
    }
    else if (typeof(value) === "object" && value.getHours && value.getMinutes) {
      return value.getHours() === 0 && value.getMinutes() === 0
    }
    else {
      return false
    }
  }
}
