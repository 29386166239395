import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static outlets = [
    "nested-form"
  ]
  static targets = [
    "deviceId",
    "deviceName",
    "deviceType",
    "moveDownButton",
    "moveUpButton",
    "position",
  ]

  initialize() {
    super.initialize()

    if (this.nestedFormOutlet.addButtonTarget.selectedIndex > 0) {
      const selectedIndex = this.nestedFormOutlet.addButtonTarget.selectedIndex
      const textArray = this.nestedFormOutlet.addButtonTarget[selectedIndex].text.split(" - ")

      this.deviceIdTarget.value = this.nestedFormOutlet.addButtonTarget[selectedIndex].value
      this.deviceTypeTarget.innerHTML = textArray.pop()
      this.deviceNameTarget.innerHTML = textArray.join(" - ")

      this.nestedFormOutlet.addButtonTarget.selectedIndex = 0
      this.nestedFormOutlet.addButtonTarget[selectedIndex].hidden = true
    } else {
      for (let i = 1; i < this.nestedFormOutlet.addButtonTarget.length; i++) {
        if (this.nestedFormOutlet.addButtonTarget[i].value == this.deviceIdTarget.value) {
          this.nestedFormOutlet.addButtonTarget[i].hidden = true
          break
        }
      }
    }

    this.nestedFormOutlet.registerItem(this)
  }

  moveDown(event) {
    event.stopPropagation()
    this.nestedFormOutlet.moveDown(parseInt(this.positionTarget.value) - 1)
  }

  moveUp(event) {
    event.stopPropagation()
    this.nestedFormOutlet.moveUp(parseInt(this.positionTarget.value) - 1)
  }

  remove(event) {
    event.stopPropagation()
    this.nestedFormOutlet.removeAndReorder(event, parseInt(this.positionTarget.value) - 1)

    for (let i = 1; i < this.nestedFormOutlet.addButtonTarget.length; i++) {
      if (this.nestedFormOutlet.addButtonTarget[i].value == this.deviceIdTarget.value) {
        this.nestedFormOutlet.addButtonTarget[i].hidden = false
        break
      }
    }
  }

  updatePosition(position, count) {
    const newPosition = position + 1

    if (newPosition == 1) {
      this.moveUpButtonTarget.classList.add("hidden")
    } else {
      this.moveUpButtonTarget.classList.remove("hidden")
    }
    if (newPosition == count) {
      this.moveDownButtonTarget.classList.add("hidden")
    } else {
      this.moveDownButtonTarget.classList.remove("hidden")
    }

    this.positionTarget.value = newPosition
    this.element.style.order = newPosition
  }
}
