import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [
    "searchForm", "searchFormQuery", "submitButton", "reservationTab",
    "reservationTabBody", "searchInProgress", "loadMoreButton"
  ]
  static values = {
    appliedSearch: String
  }

  setCurrent() {
    this.replaceStatus("current")
    this.triggerSearch()
  }

  setUpcoming() {
    this.replaceStatus("upcoming")
    this.triggerSearch()
  }

  setPast() {
    this.replaceStatus("past")
    this.triggerSearch()
  }

  setAll() {
    this.replaceStatus("all")
    this.triggerSearch()
  }

  triggerSearch() {
    this.submitSearchForm()
    this.appliedSearchValue = this.searchFormQueryTarget.value
  }

  checkKeyPressed(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.triggerSearch()
    }
  }

  replaceStatus(status_name) {
    this.searchFormTarget.action = this.searchFormTarget.action.replace(/status=.*/, "status=" + status_name)
  }

  submitSearchForm() {
    if (this.searchFormQueryTarget.value.length != 0) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.click()
      this.showSearchInProgressSpinner()
      this.removeLoadMoreButton()
    }
    else if (this.searchFormQueryTarget.value.length == 0 && this.appliedSearchValue.length != 0) {
      this.appliedSearchValue = ""
      this.reloadAllTabs()
    }
  }

  reloadAllTabs() {
    this.reservationTabTargets.forEach(turboFrame => turboFrame.src = turboFrame.src)
  }

  showSearchInProgressSpinner() {
    this.reservationTabBodyTargets.forEach(turboFrame => turboFrame.innerHTML = this.searchInProgressTarget.innerHTML)
  }

  removeLoadMoreButton() {
    this.loadMoreButtonTargets.forEach(btn => btn.remove())
  }
}
