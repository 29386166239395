import ApplicationController from "../application_controller"
import Highcharts from "highcharts/highstock"

export default class extends ApplicationController {
  static targets = [ "mainChart" ]

  connect() {
    this.initGraph()
  }

  initGraph() {
    Highcharts.setOptions({
      global: {
          useUTC: false
      }
    })

    this.chart = Highcharts.stockChart(this.mainChartTarget, {
      chart: {
          backgroundColor: "#fff",
          events: {
              load: this.loadMonthlyData.bind(this)
          },
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
      },
      accessibility: {
        enabled: false // TODO: include accessibility module
      },

      rangeSelector: {
          enabled: false,
          inputEnabled: false
      },

      scrollbar: {
          enabled: false
      },

      title: {
          text: ""
      },

      xAxis: {
          lineWidth: 0,
          minorGridLineWidth: 0,
          lineColor: "transparent",
          labels: {
              enabled: true
          },
          minorTickLength: 0,
          tickLength: 0
      },

      series: [{
          name: "Door releases",
          showInLegend: true,
          data: [],
          marker: {
              enabled: true,
              radius: 4
          },
          color: "#f2a74f"
      }, {
          name: "Calls",
          showInLegend: true,
          data: [],
          marker: {
              enabled: true,
              radius: 4
          },
          color: "#ec6961"
      }, {
          name: "New users",
          showInLegend: true,
          data: [],
          marker: {
              enabled: true,
              radius: 4
          },
          color: "#a1cc67"
      }, {
          name: "Virtual Keys",
          showInLegend: true,
          data: [],
          marker: {
              enabled: true,
              radius: 4
          },
          color: "#a656a4"
      }],

      tooltip: {
          shared: true,
          split: false,
          style: { fontSize: "0.9em" }
      },

      navigator: {
          enabled: false
      },

      legend: {
          enabled: true
      }
    })

    this.chart.showLoading("Loading data from server...")
  }

  async loadMonthlyData(e) {
    var requestUrl = $(this.element).data("href")

    var request = $.ajax({
      dataType: "json",
      url: requestUrl,
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      }
    }).done((data) => {
      var dates = $.map(data.door_releases, function(date_and_count) {
          return date_and_count[0]
      })
      this.chart.series[0].setData(data.door_releases)
      this.chart.series[1].setData(data.calls)
      this.chart.series[2].setData(data.residents)
      this.chart.series[3].setData(data.virtual_keys)
      this.chart.xAxis[0].setCategories(dates)
    }).fail(() => {
      this.chart.showLoading("Something went wrong. We are not able to show a bar.")
    })

    request.complete(() => {
      this.chart.hideLoading()
    })
  }
}
