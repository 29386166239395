import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["unitsDropdown"]

  connect() {
    // force selectize to load all items in DOM
    document.querySelectorAll("[data-controller='select-with-empty-option']").forEach(item => {
      item.selectize?.refreshOptions(false)
    })

    this.disableOccupiedUnits()
  }

  disableOccupiedUnits() {
    this.resetAllDropdownOptions()

    const occupied_units = this.findSelectedUnits()

    if(occupied_units.length > 0) {
      occupied_units.forEach(unit => {
        this.disableUnit(unit)
      })
    }
  }

  resetAllDropdownOptions() {
    document.querySelectorAll(".selectize-dropdown-content .option").forEach(item => item.setAttribute("data-selectable", true))
  }

  findSelectedUnits() {
    return this.unitsDropdownTargets.map(item =>
      {
        // check if item is currently visible, otherwise selected items from mobile/desktop views would be always disabled
        if(item.parentNode.offsetParent != undefined) {
          return item.value
        }
      }
    )
  }

  disableUnit(unit_id) {
    document.querySelectorAll(`.selectize-dropdown-content [data-value='${unit_id}']`).forEach(item => {
      if(item.dataset.value != "") {
        item.removeAttribute("data-selectable")
      }
    })
  }
}
