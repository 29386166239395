import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "modelNumber", "deviceType" ]

  connect() {
    this.buildModelNumberOptions()
    this.initializeModelNumberSelectize()

    // Initialize the picker with it's current state if a picker exists
    if (!this.modelNumberSelectize) return

    const currentValue = this.modelNumberSelectize.getValue()

    this.scopeModelNumbers()

    if (currentValue) {
      this.modelNumberSelectize.setValue(currentValue, true)
    }

    this.selectDeviceType()
  }

  disconnect() {
    this.destroyModelNumberSelectize()
  }

  buildModelNumberOptions() {
    this.modelNumberOptionsMap = {}
    this.modelNumberOptions = []

    if (!this.hasModelNumberTarget) return

    this.modelNumberTarget.querySelectorAll("option").forEach((option) => {
      if (option.value === "") return

      this.modelNumberOptionsMap[option.value] = {
        number: option.dataset.number,
        description: option.dataset.description,
        deviceType: option.dataset.deviceType,
        group: option.dataset.group,
        value: option.value,
        name: option.innerHTML
      }

      this.modelNumberOptions.push(this.modelNumberOptionsMap[option.value])
    })
  }

  initializeModelNumberSelectize() {
    if (!this.hasModelNumberTarget) return

    const values = this.modelNumberOptionsMap
    if (!values) return

    $(this.modelNumberTarget).selectize({
      searchField: ["name", "value"],
      render: {
        item: function(item, escape) {
          const value = values[item.value]
          return `
            <div class="model_number_picker__selected_item">
              <span class="model_number_picker__selected_item__number">
                ${escape(value.number)}
              </span>
              <span class="model_number_picker__selected_item__description">
                (${escape(value.description)})
              </span>
            </div>
          `
        },
        option: function(item, escape) {
          const value = values[item.value]
          return `
            <div class="model_number_picker__option">
              <div class="model_number_picker__option__number">
                ${escape(value.number)}
              </div>
              <div class="model_number_picker__option__description">
                ${escape(value.description)}
              </div>
            </div>
          `
        }
      }
    })

    this.modelNumberSelectize = this.modelNumberTarget.selectize

    this.modelNumberSelectize.on("change", this.selectDeviceType.bind(this))
  }

  destroyModelNumberSelectize() {
    if (!this.hasModelNumberTarget) return
    if (!this.modelNumberSelectize) return

    this.modelNumberSelectize.destroy()
    this.modelNumberSelectize = null
  }

  scopeModelNumbers() {
    if (!this.modelNumberSelectize) return
    if (!this.hasDeviceTypeTarget) return

    const deviceType = this.deviceTypeTarget.value

    const values = this.modelNumberOptions
    if (!values) return

    // Clear the current selection so that the user has to pick again, else
    // it would be possible to have a model number without a device type
    this.modelNumberSelectize.setValue(null, true)
    this.modelNumberSelectize.clearOptions()

    for (const i in values) {
      const value = values[i]

      if (value.value === "") continue
      if (deviceType !== "" && value.deviceType !== deviceType) continue

      this.modelNumberSelectize.addOption({
        name: value.name,
        value: value.value,
        optgroup: value.group
      })
    }
  }

  selectDeviceType() {
    if (!this.hasDeviceTypeTarget) return

    const values = this.modelNumberOptionsMap
    if (!values) return

    const value = values[this.modelNumberSelectize.getValue()]
    if (!value) return

    this.deviceTypeTarget.value = value.deviceType
  }
}
