/* Creates an input which formats it's value according to the given temaplate
 *
 * E.g. to get an input that formats numbers like "123.45" to "$ 123.45"
 * ```slim
 *   = f.text_field :reservation_fee, \
 *   data: { \
 *     controller: 'formatted-input', \
 *     formatted_input_template_value: '$ {value}', \
 *     action: 'input->formatted-input#format' \
 *   }
 * ```
 *
 */
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  TEMPLATE_TARGET = "{value}"
  static values = {
    template: String
  }

  connect() {
    if (!this.hasTemplateValue) this.templateValue = `${this.TEMPLATE_TARGET}`

    this.format()
  }

  format(event) {
    const input = this.element
    if (!input) return

    const value = this.preProcessValue(input.value)
    const formattedValue = this.templateValue.replaceAll(this.TEMPLATE_TARGET, value)
    input.value = this.postProcessValue(formattedValue)
  }

  preProcessValue(value) {
    if (!value) return value

    // Find the start of the template
    const prefixLenght = this.templateValue.indexOf(this.TEMPLATE_TARGET)
    if (prefixLenght < 0) return value

    // Find the end of the template
    const suffixLength = this.templateValue.length - (prefixLenght + this.TEMPLATE_TARGET.length)

    return value.slice(prefixLenght, value.length - suffixLength)
  }

  postProcessValue(value) {
    return value
  }
}
