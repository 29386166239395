import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["defaultLanguageSelect", "supportedLanguagesSelect"]

  connect(){
    if (!this.hasSupportedLanguagesSelectTarget) {
      return
    }

    const $selectize = $(this.supportedLanguagesSelectTarget).selectize({
      plugins: ["remove_button"],
      allowEmptyOption: true
    })

    this.selectize =  $selectize[0].selectize
    this.addDefaultLanguageToSupportedLanguages()
    this.selectize.on("change", this.addDefaultLanguageToSupportedLanguages.bind(this))
  }

  disconnect(){
    if (!this.selectize){
      return
    }

    this.selectize.destroy()
  }

  addDefaultLanguageToSupportedLanguages(){
    if (!this.hasDefaultLanguageSelectTarget || !this.hasSupportedLanguagesSelectTarget) {
      return
    }

    this.selectize.addItem(this.defaultLanguageSelectTarget.value)
  }
}
