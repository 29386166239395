import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["dragAndDropArea", "imagePreview", "fileInput", "removeImageInput", "removeImageContainer"]
  static values = {
    maximumFileSize: Number,
    supportedTypes: Array,
    errorPopupTitle: String,
    errorPopupPrompt: String,
    tooLargeErrorMessage: String,
    notSupportedTypeErrorMessage: String,
    unrepresentableFilePlaceholderImageUrl: String
  }
  static classes = ["dragAreaActive"]

  connect() {
    if (this.imagePresent) {
      this.previewFiles()
    }
    else {
      this.removeFiles(null)
    }
  }

  disconnect() {
    this.imagePreviewFileReader = null
  }

  previewFiles() {
    if (!this.hasFileInputTarget) return
    if (!this.hasImagePreviewTarget) return

    const files = this.fileInputTarget.files

    if (this.hasRemoveImageInputTarget) this.removeImageInputTarget.checked = false

    this.previewFile(files[0])
  }

  previewFile(file) {
    if (!file) return

    if (file.type.startsWith("image/")) {
      this.previewImage(file)
    }
    else {
      this.previewGenericFile(file)
    }
  }

  previewImage(file) {
    const imagePreviewTarget = this.imagePreviewTarget

    this.imagePreviewFileReader = this.imagePreviewFileReader || new FileReader()

    this.imagePreviewFileReader.addEventListener("load", function () {
      imagePreviewTarget.src = this.result
    })

    this.imagePreviewFileReader.readAsDataURL(file)
  }

  previewGenericFile(_file) {
    if (!this.hasUnrepresentableFilePlaceholderImageUrlValue) return

    this.imagePreviewTarget.src = this.unrepresentableFilePlaceholderImageUrlValue
  }

  clearImagePreview() {
    if (!this.hasImagePreviewTarget) return

    this.imagePreviewTarget.removeAttribute("src")
  }

  assignFiles(event) {
    event.preventDefault()
    event.stopPropagation()

    this.dragEventCounter = 1
    this.deactivateDropArea(event)

    if (!this.hasFileInputTarget) return
    if (!event.dataTransfer) return

    const files = event.dataTransfer.files
    this.fileInputTarget.files = files
    this.validateFiles()
    this.previewFiles()
  }

  removeFiles(event) {
    let target = null

    if (this.hasRemoveImageInputTarget) target = this.removeImageInputTarget

    if (event && event.target && event.target.type === "checkbox") target = event.target

    if (event && this.hasFileInputTarget && this.fileInputTarget.files.length === 0 && !target.checked) {
      event.preventDefault()
      target.checked = true
    }

    this.clearSelectedFiles()
    this.clearImagePreview()
    if (this.hasRemoveImageInputTarget) this.hideElement(this.removeImageContainerTarget)
  }

  clearSelectedFiles() {
    if (!this.hasFileInputTarget) return

    this.fileInputTarget.value = ""
  }

  processDragOverDragArea(event) {
    event.preventDefault()
  }

  activateDropArea(event) {
    if (!this.hasDragAndDropAreaTarget) return

    event.preventDefault()
    this.dragEventCounter = this.dragEventCounter || 0
    this.dragEventCounter++

    if (this.dragEventCounter > 1) return
    this.dragAndDropAreaTarget.classList.add(this.dragAreaActiveClass)
  }

  deactivateDropArea(event) {
    if (!this.hasDragAndDropAreaTarget) return

    event.preventDefault()
    this.dragEventCounter--

    if (this.dragEventCounter > 0) return
    this.dragAndDropAreaTarget.classList.remove(this.dragAreaActiveClass)
  }

  validateFiles(event) {
    if (!this.hasFileInputTarget) return

    const imageFiles = this.fileInputTarget.files

    if (imageFiles.length === 0) return
    if (this.hasRemoveImageInputTarget) this.removeImageInputTarget.checked = false

    const errorMessages = []

    Array.from(imageFiles).forEach((file) => {
      const errors = this.validateFile(file)
      if (errors.length === 0) return

      errorMessages.push(this.formatErrorMessages(file, errors))
    })

    if (errorMessages.length === 0) {
      this.fileInputTarget.dataset.pendingReselect = false
      if (this.hasRemoveImageContainerTarget) this.showElement(this.removeImageContainerTarget)
      return
    }

    this.removeFiles(event)

    this.fileInputTarget.dataset.pendingReselect = true

    Rails.confirm(
      JSON.stringify(
        {
          title: this.errorPopupTitleValue || "Invalid image(s) selected",
          body: errorMessages.flat().join("\n"),
          confirm: this.errorPopupPromptValue || "Select another image"
        }
      ),
      this.fileInputTarget
    )
  }

  validateFile(file) {
    const errors = []

    if (this.hasMaximumFileSizeValue && file.size > this.maximumFileSizeValue) {
      errors.push("tooLarge")
    }

    if (this.hasSupportedTypesValue && !this.supportedTypesValue.includes(file.type)) {
      errors.push("notSupportedType")
    }
    if (errors.length === 0) {
      if (this.hasRemoveImageContainerTarget) this.showElement(this.removeImageContainerTarget)
    }
    return errors
  }

  formatErrorMessages(file, errors) {
    console.log(`[VALIDATION ERROR] file: ${file}, errors: ${errors}`)

    const messages = []
    const controller = this

    errors.forEach((error) => {
      let message = controller[`${error}ErrorMessageValue`]

      if (message && message !== "") {
        message = message.replaceAll("%{file_name}", file.name)
        message = message.replaceAll("%{file_type}", file.type)
        messages.push(message)
      }
      else {
        messages.push(`${error} ERROR`)
      }
    })

    return messages
  }

  hideElement(target) {
    target.classList.add("hidden")
  }

  showElement(target) {
    target.classList.remove("hidden")
  }

  get imagePresent() {
    if (this.hasFileInputTarget && this.fileInputTarget.files.length > 0) return true
    if (this.hasImagePreviewTarget && this.imagePreviewTarget.src) return true

    return false
  }
}
