import { Turbo } from "@hotwired/turbo-rails"

// Custom turbo confirmation modal
Turbo.setConfirmMethod((message, element) => {
  let locale = {}

  if (document.body.dataset.confirmationModalLocale) {
    try {
      locale = JSON.parse(document.body.dataset.confirmationModalLocale)
    }
    catch {}
  }

  const titleLabel = locale.title || "Are you sure?"
  const cancelLabel = locale.cancel || "Cancel"
  const confirmLabel = locale.confirm || "Confirm"

  const modalContainer = document.createElement("div")

  modalContainer.innerHTML = `
    <div class="confirmation">
      <div id="turbo-confirm" class="modal fade">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header pt-[25px]">
              <h4 class="modal-title">${titleLabel}</h4>
            </div>

            <div class="modal-body"></div>

            <div class="custom-modal-footer">

              <div class="row display-desktop">
                <div class="col-md-12">
                  <div class="pull-right">
                    <div class="button-group inline-block m-r-sm hidden-xs hidden-sm">
                      <a href="" class="btn btn-link">${cancelLabel}</a>
                      <button value="confirm" class="btn btn-primary">${confirmLabel}</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row display-mobile mobile_view">
                <div class="col-md-12">
                  <div class="flex-mobile justify-between hidden-md hidden-lg">
                    <a href="" class="btn btn-link">${cancelLabel}</a>
                    <button value="confirm" class="btn btn-primary">${confirmLabel}</button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  `

  document.body.appendChild(modalContainer)
  const modal = modalContainer.querySelector("#turbo-confirm")

  modal.querySelector(".modal-body").innerHTML = ""
  modal.querySelector(".modal-body").appendChild(document.createTextNode(message))

  $(modal).modal("show")

  return new Promise((resolve, reject) => {
    modal.querySelector(".custom-modal-footer").addEventListener("click", (event) => {
      event.preventDefault()
      if (event.target.classList.contains("btn")) {
        $(modal).modal("hide")
        $(modal).on("hidden.bs.modal", (e) => modalContainer.remove())

        resolve(event.target.value === "confirm")

        if (event.target.value !== "confirm") {
          element.dispatchEvent(new CustomEvent("turbo-confirm:cancel"))
        }
      }
    }, {once: true})
  })
})
