import.meta.glob("../admin/initializers/*.js", { eager: true })

import jQuery from "jquery"
Object.assign(window, { $: jQuery, jQuery })

import "@hotwired/turbo-rails"

import "trix"
import "@rails/actiontext"
import "../shared/trix-editor-overrides"

import Rails from "@rails/ujs"
Rails.start()
window.Rails = Rails

import "bootstrap"

import "selectize"

import "../shared/controllers"
import "../admin/controllers"
