import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["searchInput"]
  static values = {
    searchEvent: String
  }

  dispatchSearchEvent() {
    const event = new CustomEvent(this.searchEventValue, { detail: { search_query: this.searchInputTarget.value } })

    window.dispatchEvent(event)
  }
}
