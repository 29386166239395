import { Controller } from "@hotwired/stimulus"
import { useDebounce, useClickOutside } from "stimulus-use"

export default class StimulusMultiselect extends Controller {
  static targets = [
    "hidden",
    "list",
    "search",
    "preview",
    "dropdown",
    "item",
    "inputContainer",
  ]

  static values = {
    items: Array,
    searchUrl: String,
    disabled: { type: Boolean, default: false },
    focusedIndex: { type: Number, default: null },
  }

  static debounces = ["search"]

  connect() {
    useDebounce(this, { wait: 300 })
    useClickOutside(this)

    this.hiddenTarget.insertAdjacentHTML("afterend", this.template)
    this.initializeSelectedValues()

    this.dropdownTarget.setAttribute("role", "listbox")
    this.dropdownTarget.setAttribute("aria-multiselectable", "true")
    this.searchTarget.setAttribute("aria-expanded", "false")
  }

  initializeSelectedValues() {
    this.selectedValueChanged()
    Array.from(this.hiddenTarget.options).forEach((option) => {
      if (option.selected) {
        this.checkItem(option.value)
      }
    })
  }

  selectedValueChanged() {
    const selectedOptions = this.hiddenTarget.selectedOptions.length
    const placeholderText =
      selectedOptions > 0
        ? `Select People... (${selectedOptions} Selected)`
        : "Select People..."
    this.searchTarget.placeholder = placeholderText
  }

  checkItem(value) {
    const checkbox = this.listTarget.querySelector(
      `input[type="checkbox"][data-value="${value}"]`
    )
    if (checkbox) checkbox.checked = true
  }

  async search() {
    this.searchLocal()
  }

  searchLocal() {
    this.openDropdown()
    const searchText = this.searchTarget.value.toLowerCase()

    const filteredItems = Array.from(this.hiddenTarget.options)
      .filter((option) => option.text.toLowerCase().includes(searchText))
      .map((option) => ({
        text: option.text,
        value: option.value,
        selected: option.selected,
      }))

    this.listTarget.innerHTML =
      filteredItems.length > 0
        ? this.items(filteredItems)
        : this.noResultsTemplate()
  }

  toggleDropdown() {
    const isOpen = this.dropdownTarget.classList.contains(
      "multiselect__dropdown--open"
    )
    this.dropdownTarget.classList.toggle(
      "multiselect__dropdown--open",
      !isOpen
    )
    this.searchTarget.setAttribute("aria-expanded", String(!isOpen))
    this.inputContainerTarget.style.display = ""
    if (!isOpen && this.itemsValue.length) this.searchTarget.focus()
    else this.searchTarget.blur()
  }

  checkBoxChange(event) {
    event.preventDefault()
    this.searchTarget.focus()
    this.toggleItem(event.currentTarget)
  }

  toggleItem(input) {
    const option = Array.from(this.hiddenTarget.options).find(
      (option) => option.value === input.dataset.value
    )

    if (option) {
      option.selected = input.checked
    } else if (input.checked) {
      const newOption = new Option(
        input.dataset.text,
        input.dataset.value,
        true,
        true
      )
      this.hiddenTarget.add(newOption)
    }

    this.selectedValueChanged()
  }

  onKeyDown(event) {
    const method = this[`on${event.key}Keydown`]
    if (method) {
      method.call(this, event)
    }
  }

  sibling(forward) {
    const newIndex = forward
      ? (this.focusedIndexValue + 1) % this.itemTargets.length
      : this.focusedIndexValue - 1 < 0
      ? this.itemTargets.length - 1
      : this.focusedIndexValue - 1
    return newIndex
  }

  navigate(toIndex) {
    if (this.focusedIndexValue !== null) {
      const prevItem = this.itemTargets[this.focusedIndexValue]
      prevItem.closest("li").classList.remove("multiselect__focused")
    }

    this.focusedIndexValue = toIndex

    const newItem = this.itemTargets[this.focusedIndexValue]
    newItem.closest("li").classList.add("multiselect__focused")
    newItem.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }

  focusSearch() {
    this.inputContainerTarget.style.display = ""
    this.searchTarget.focus()
  }

  clickOutside(event) {
    if (this.dropdownTarget.classList.contains("multiselect__dropdown--open")) {
      this.toggleDropdown()
    }
  }

  items(items) {
    return items.map((item) => this.itemTemplate(item)).join("")
  }

  get template() {
    return `
    <div class="multiselect__container" data-stimulus-multiselect-target="container" data-action="click->stimulus-multiselect#toggleDropdown focus->stimulus-multiselect#focusSearch" tabindex="0" data-turbo-cache="false">
      <div class="multiselect__preview" data-stimulus-multiselect-target="preview"></div>
      <div class="multiselect__input-container" data-stimulus-multiselect-target="inputContainer">${this.inputTemplate}</div>
    </div>
    <div style="position: relative">
      <div class="multiselect__dropdown" data-stimulus-multiselect-target="dropdown">
        <ul class="multiselect__list" data-stimulus-multiselect-target="list">
          ${this.allItems}
        </ul>
      </div>
    </div>
  `
  }

  noResultsTemplate() {
    return "<div class=\"multiselect__no-result\">No results found</div>"
  }

  get inputTemplate() {
    return `
    <input type="text" class="multiselect__search focus:ring-0" placeholder="${
      this.element.dataset.placeholder
    }"
           data-stimulus-multiselect-target="search" ${
             this.disabledValue ? "disabled" : ""
           }
           data-action="stimulus-multiselect#search keydown->stimulus-multiselect#onKeyDown">
  `
  }

  updatePlaceholder() {
    const selectedCount = this.hiddenSelect.selectedOptions.length
    const placeholderText =
      selectedCount > 0
        ? `Select People... (${selectedCount} Selected)`
        : "Select People..."
    this.searchTarget.placeholder = placeholderText
  }

  isDropdownOpen() {
    return this.dropdownTarget.classList.contains(
      "multiselect__dropdown--open"
    )
  }

  openDropdown() {
    if (!this.isDropdownOpen()) {
      this.dropdownTarget.classList.add("multiselect__dropdown--open")
      this.searchTarget.setAttribute("aria-expanded", "true")
    }
  }

  onHomeKeydown(event) {
    event.preventDefault()
    const firstItem = this.itemTargets[0]
    if (firstItem) {
      this.navigate(firstItem)
    }
  }

  onEndKeydown(event) {
    event.preventDefault()
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    if (lastItem) {
      this.navigate(lastItem)
    }
  }

  onArrowDownKeydown(event) {
    event.preventDefault()
    this.openDropdown()
    const newIndex = this.sibling(true)
    if (newIndex !== undefined) this.navigate(newIndex)
  }

  onArrowUpKeydown(event) {
    event.preventDefault()
    const newIndex = this.sibling(false)
    this.navigate(newIndex)
  }

  onEnterKeydown(event) {
    if (this.focusedItem) {
      this.focusedItem.click()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  onEscapeKeydown(event) {
    if (this.dropdownTarget.classList.contains("multiselect__dropdown--open")) {
      this.toggleDropdown()

      event.preventDefault()
      event.stopPropagation()
    }
  }

  get focusedItem() {
    return this.focusedIndexValue !== null
      ? this.itemTargets[this.focusedIndexValue]
      : null
  }

  itemTemplate(item) {
    const isChecked = item.selected ? "checked" : ""
    const ariaSelected = item.selected
      ? "aria-selected='true'"
      : "aria-selected='false'"
    return `
      <li role="option" ${ariaSelected}>
        <label>
          <input type="checkbox" ${isChecked} data-value="${item.value}" data-text="${item.text}"
          data-action="stimulus-multiselect#checkBoxChange" data-stimulus-multiselect-target="item" tabindex="-1">
          <span>${item.text}</span>
        </label>
      </li>
    `
  }

  get allItems() {
    return this.items(this.itemsValue)
  }
}
