import ApplicationController from "../application_controller"

export default class extends ApplicationController {
    connect() {
    }

    countDown(timestamp) {
        function component(x, v) {
            return Math.floor(x / v)
        }

        function pad(num) {
            return num > 9 ? num : "0"+num
        }

        setInterval(() => {
            timestamp--
            const hours = component(timestamp, 60 * 60) % 24,
                minutes = component(timestamp, 60) % 60,
                seconds = component(timestamp, 1) % 60

            const expireField = document.getElementById("expireField")
            if (expireField) {
              expireField.innerText = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
            }
        },1000)
    }

    confirmShowMasterPIN(event) {
        event.preventDefault()

        if (Rails.confirm({
            title: "Show Master PIN",
            body: "Are you sure you want to display the master key? This action will be logged and displayed to others. The log can't be changed or deleted!",
            confirm: "Confirm"
        }, event.target)) {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: event.target.dataset.confirmLink,
                xhrFields: {
                    withCredentials: true
                },
                success: (response) => {
                    $("#masterPinDialog .modal").modal("show")
                    document.getElementById("pinField").innerText = response.current_key

                    const diff = response.expires_at - (Date.now() / 1000)
                    this.countDown(diff)
                },
                error: (_response) => { alert("open failed") }
            })
        }
    }
}
