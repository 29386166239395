document.addEventListener("turbo:frame-missing", function(event) {
  const { detail: { response, visit } } = event

  // If the frame's request resulted in a redirect, visit the redirect target
  // NOTE: This only works because most controllers redefine the layout they
  // want to use, if it wasn't so Turbo's layout would be used and this would
  // result in a completely broken layout
  if (response.redirected) {
    event.preventDefault()
    // This replaces the body with the one from the response, if we were to
    // navigate here then we would have to render the same page twice, once
    // with the response we just got and are responding to and another when
    // we navigate to the redirect target
    visit(response)
  }
})
