import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = { url: String }

  connect() {
    $(this.element).selectize({
      valueField: "serial_number",
      labelField: "serial_number",
      searchField: "serial_number",
      maxItems: 1,
      create: true,
      render: {
        option: function(item, escape) {
          return `
            <div>
              <div class="title">${escape(item.serial_number)}</div>
            <div>
          `
        }
      },
      load: (query, callback) => {
        if (!query.length) return callback()
        $.ajax({
          url: `${this.urlValue}${query}`,
          type: "GET",
          error: function() { callback() },
          success: function(res) {
            callback(res.data)
          }
        })
      }
    })
  }
}
