import { Turbo } from "@hotwired/turbo-rails"

Turbo.StreamActions.redirect_to = function() {
  const location = this.getAttribute("location")

  if (!location) return

  const url = new URL(location, window.location.origin)

  // If the redirect is to the authorization URL we wannt to use the browser's
  // default behaviour to avoid issues with the session not getting persisted
  if (location.startsWith("/oauth/authorize")) {
    window.location.href = location
  }
  else if (url.origin === window.location.origin) {
    Turbo.visit(location)
  }
  // If the redirect is to an external URL we want to use the browser's default
  // behaviour to avoid issues with the session not getting persisted
  else {
    window.location.href = location
  }
}
