import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "timeFromInput",
    "timeToInput",
    "activeAllDayCheckbox"
  ]

  connect() {
    if (this.activeAllDay) {
      this.activeAllDayCheckboxTarget.checked = true
      this.setActiveAllDay()
    }
  }

  makeActiveAllDay(event) {
    if (event && event.target.checked === false) return

    this.setActiveAllDay()
  }

  checkActiveAllDay(event) {
    if (!this.activeAllDay) {
      this.activeAllDayCheckboxTarget.checked = false
    }
  }

  setActiveAllDay() {
    this.setTimeFor(this.timeFromInputTarget, "00:00")
    this.setTimeFor(this.timeToInputTarget, "00:00")
  }

  setTimeFor(field, time) {
    if (!field) return

    if (field._flatpickr) {
      field._flatpickr.setDate(time, true, "H:i")
    }
    else {
      field.value = "00:00"
    }
  }

  get activeAllDay() {
    return this.timeFromInputTarget.value === "00:00" && this.timeToInputTarget.value === "00:00"
  }
}
