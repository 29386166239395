import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["triggerInput", "accessGroupWrapper"]

  connect() {
    this.toggleAccessGroupSelect()
  }

  triggerChanged() {
    this.toggleAccessGroupSelect()
  }

  toggleAccessGroupSelect() {
    if (!this.hasTriggerInputTarget) return

    if (this.triggerInputTarget.value === "none") {
      this.accessGroupWrapperTarget.classList.add("hidden")
    } else {
      this.accessGroupWrapperTarget.classList.remove("hidden")
    }
  }
}
