/* Creates an input which prevents the user from entering any value other than
 * numbers and formats the value according to the provided template.
 *
 * E.g. to get an input that formats numbers like "123.456" to "$ 123.45"
 * ```slim
 *   = f.text_field :reservation_fee, \
 *   data: { \
 *     controller: 'currency-input', \
 *     currency_input_template_value: '$ {value}', \
 *     action: 'input->currency-input#format' \
 *   }
 * ```
 *
 */
import FormattedInputController from "./formatted_input_controller"

export default class extends FormattedInputController {
  NON_NUMBER_REGEX = /[^\d\.]/ig

  preProcessValue(value) {
    if (!value || value === "") value = "0.0"
    return this.ensureDecimalNumberFormat(value)
  }

  ensureDecimalNumberFormat(value) {
    value = value.replaceAll(this.NON_NUMBER_REGEX, "")
    const decimalPointPosition = value.indexOf(".")

    // Truncate decimal spaces to just the first two
    if (decimalPointPosition >= 0) {
      const decimalValue = value.substring(decimalPointPosition + 1)
                                .substring(0, 2)
      value = `${value.substring(0, decimalPointPosition)}.${decimalValue}`
    }

    return value
  }
}
