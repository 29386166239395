import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["monthSelect", "daySelect"]
  static NUMBER_OF_DAYS_IN_MONTH = {
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
  }

  connect() {
    this.refreshDaysSelect()
  }

  refreshDaysSelect() {
    const selectedMonth = this.monthSelectTarget.value
    const selectedDay = this.daySelectTarget.value
    const numberOfDaysInMonth = this.constructor.NUMBER_OF_DAYS_IN_MONTH[selectedMonth]

    if (selectedDay > numberOfDaysInMonth) {
      this.daySelectTarget.value = 1
    }

    this.daySelectTarget.querySelectorAll("option").forEach(optionEl => {
      optionEl.disabled = optionEl.value > numberOfDaysInMonth
    })
  }
}
