import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = ["addNestedItem", "nestedItem"]
    static values = {
        maxNestedItems: Number
    }

    connect() {
        this.checkNestedItemsCount()
    }

    checkNestedItemsCount() {
        const visibleItems = this.nestedItemTargets.filter(item => item.style.display !== "none")

        if (visibleItems.length >= this.maxNestedItemsValue) {
            this.addNestedItemTarget.classList.add("hidden")
        } else {
            this.addNestedItemTarget.classList.remove("hidden")
        }
    }
}
