import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [
    "selector"
  ]

  static values = {
    mapping: Object
  }

  connect() {
    this.selectorTarget.value = (new URLSearchParams(window.location.search).get("type") || "unit_admin")
    this.selectorTarget.dispatchEvent(new Event("change"))
  }

  initialize() {
    super.initialize()
    if (this.hasMappingValue) {
      this.select()
    }
  }

  select(event) {
    if (this.mappingValue.hasOwnProperty(this.selectorTarget.value)) {
      const data = this.mappingValue[this.selectorTarget.value]
      if (data.hasOwnProperty("show")) {
        this.element.querySelectorAll(data["show"]).forEach(element => {
          element.classList.remove("hidden")
        })
      }
      if (data.hasOwnProperty("hide")) {
        this.element.querySelectorAll(data["hide"]).forEach(element => {
          element.classList.add("hidden")
        })
      }
    }
  }
}
