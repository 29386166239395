import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [
    "addButton",
    "tabContent",
    "tabSelector"
  ]

  static values = { addAdmin: String, addAttendant: String }


  connect() {
    this.selectTab(new URLSearchParams(window.location.search).get("tab") || "building_admin")
  }

  selectTabClicked(event) {
    event.preventDefault()
    const url = new URL(event.target.href)
    const tab = url.searchParams.get("tab")
    window.Turbo.navigator.history.push(url)
    this.selectTab(tab)
  }

  selectTab(tab) {
    this.tabSelectorTargets.concat(this.tabContentTargets).forEach(element => {
      element.classList.toggle("active", element.dataset.tab == tab)
    })

    if (this.hasAddButtonTarget) {
      this.addButtonTarget.href = this.addButtonTarget.href.split("?")[0] + "?type=" + tab
      if (tab == "front_desk_attendant") {
        this.addButtonTarget.textContent = this.addAttendantValue
      } else {
        this.addButtonTarget.textContent = this.addAdminValue
      }
    }
  }
}
