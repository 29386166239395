import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  connect() {
    $(this.element).selectize({
      delimiter: ",",
      splitOn: ",",
      persist: false,
      selectOnTab: true,
      plugins: ["remove_button"],
      create: function(input) {
        return {
          value: input,
          text: input
        }
      },
    })
  }
}
