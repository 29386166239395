import ApplicationController from "../application_controller"
import { formatDistance, addSeconds } from "date-fns"

export default class extends ApplicationController {
  static targets = ["code", "expiration", "bar", "barText"]

  connect() {
    if (this.hasExpirationTarget) {
      this.expirationTarget.innerHTML =
        "<div class=\"key_locker_master_key__expires_at__text\" data-target=\"key-locker--master-key-totp.barText\"></div>" +
        "<div class=\"key_locker_master_key__expires_at__bar_container\"><div class=\"key_locker_master_key__expires_at__bar_container__bar\" data-target=\"key-locker--master-key-totp.bar\"></div></div>"
      this.expirationTarget.classList.add("key_locker_master_key__expires_at--bar")
    }

    this.tick()
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  tick() {
    this.adjustBarWidth()
    clearTimeout(this.timer)
    this.timer = setTimeout(this.tick.bind(this), 500)
  }

  adjustBarWidth() {
    if (!this.hasBarTarget) {
      return
    }

    if (this.hasExpired()) {
      this.fetchMasterKey()
    }

    if (this.hasBarTextTarget) {
      this.barTextTarget.innerHTML =
        "Expires in " + formatDistance(new Date(), addSeconds(new Date(), this.expiresIn()))
    }

    this.barTarget.style.width = `${(100.0 * this.expiresIn()) / this.interval()}%`
  }

  hasExpired() {
    const expiresAt = Number.parseInt(this.data.get("expiresAt"), 10)
    const currentTime = Math.floor(Date.now() / 1000)

    return currentTime >= expiresAt
  }

  expiresIn() {
    const expiresAt = Number.parseInt(this.data.get("expiresAt"), 10)
    const currentTime = Math.floor(Date.now() / 1000)

    return expiresAt - currentTime
  }

  interval() {
    return Number.parseInt(this.data.get("interval"), 10)
  }

  fetchMasterKey() {
    Rails.ajax({
      type: "POST",
      url: window.location.href,
      accept: "json",
      dataType: "json",
      success: (response) => {
        if (this.hasCodeTarget) {
          this.codeTarget.innerHTML = response.current_key
        }

        this.data.set("interval", response.interval)
        this.data.set("expiresAt", response.expires_at)
      },
      error: (response) => { console.log("Master key fetch failed", response) }
    })
  }
}
