/* Returns the CSS selector path to a given element */
export default function(element) {
  const pathParts = []

  let currentElement = element

  while(currentElement) {
    let selector = currentElement.nodeName.toLowerCase()

    if (selector === "body") break

    if (currentElement.id && currentElement.id !== "") selector += `#${currentElement.id}`

    for(let i = 0; i < currentElement.classList.length; i++) {
      selector += `.${currentElement.classList.item(i)}`
    }

    pathParts.push(selector)

    currentElement = currentElement.parentNode
  }

  return pathParts.reverse().join(" > ")
}
