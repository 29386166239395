import ApplicationController from "../../application_controller"

/**
 * The Stimulus controller for handling door options.
 */
export default class extends ApplicationController {
  /**
   * The targets for this controller.
   */
  static targets = ["door"]

  /**
   * The values for this controller.
   */
  static values = {
    loadUnitsUrl: String,
    buildingId: Number,
    assigned: Boolean
  }

  /**
   * Connects the controller to the page when it loads.
   */
  connect() {
    this.enableDoorSelectize()
    this.selectDefaultDoorOption()
  }

  /**
   * Selects the default door option if no door option is selected.
   */
  selectDefaultDoorOption() {
    const doorSelectize = this.doorTarget.selectize
    if (!doorSelectize.getValue() && this.assignedValue) {
      this.addCommonLockOption(doorSelectize)
      doorSelectize.addItem("common_lock", true)
    }
  }

  enableDoorSelectize() {
    const doorSelectize = this.doorTarget.selectize
    doorSelectize.enable()
  }

  /**
   * Loads the units for the selected building.
   */
  async loadUnits() {
    const doorSelectize = this.doorTarget.selectize
    doorSelectize.clearOptions()

    if (this.buildingIdValue !== "") {
      try {
        const response = await this.fetchUnits()
        this.addUnassignedLockOption(doorSelectize)
        this.addCommonLockOption(doorSelectize)
        this.addUnassignedLockOption(doorSelectize)

        doorSelectize.load((callback) => {
          doorSelectize.addOptionGroup("unit_locks", { label: "" })
          response.units.map((unit) =>
            Object.assign(unit, { optgroup: "unit_locks" })
          )
          callback(response.units)
        })
      } catch (error) {
        console.error("Error loading units:", error)
      }
    }
  }

  /**
   * Adds the common lock option to the door selectize.
   * @param {Object} doorSelectize - The door selectize.
   */
  addCommonLockOption(doorSelectize) {
    doorSelectize.addOption({ text: "Common Lock", value: "common_lock" })
    doorSelectize.refreshOptions(false)
  }

  /**
   * Adds the unassigned lock option to the door selectize.
   * @param {Object} doorSelectize - The door selectize.
   */
  addUnassignedLockOption(doorSelectize) {
    doorSelectize.addOption({ text: "Unassigned", value: "unassigned"})
    doorSelectize.refreshOptions(false)
  }

  /**
   * Makes a GET request to fetch the units for the selected building.
   * @returns {Promise} A Promise that resolves with the response from the server.
   */
  async fetchUnits() {
    const url = `${this.loadUnitsUrlValue}/?building_id=${this.buildingIdValue}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    })

    return response.json()
  }
}
