window.addEventListener("trix-file-accept", function(event) {
    const acceptedTypes = ["image/jpeg", "image/png"]
    if (!acceptedTypes.includes(event.file.type)) {
        event.preventDefault()
        alert("Only support attachment of jpeg or png files")
    }
    if (event.file.size > 1024*1024*3) {
        event.preventDefault()
        alert("Only support attachment files upto size 3MB!")
    }
})