import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    updatePath: String,
    paramsKey: String
  }

  disableAutoAssign() {
    Turbo.clearCache()
    this.sendRequest( { auto_assign: 0 } )
  }

  enableAutoAssign() {
    Turbo.clearCache()
    this.sendRequest({ auto_assign: 1 })
  }

  sendRequest(params) {
    $.ajax({
      url: this.updatePathValue,
      data: {
        [this.paramsKeyValue]: params
      },
      type: "PUT",
      dataType: "script"
    })
  }
}
