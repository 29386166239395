import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  loadDevicesPage(event) {
    if (event.target.value.length > 0) {
      Turbo.visit(event.target.value)
    }
  }
}
