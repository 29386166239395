import ApplicationController from "../application_controller"
import Highcharts from "highcharts/highstock"

export default class extends ApplicationController {
  static targets = [ "mainChart" ]

  connect() {
    this.initGraph()
  }

  initGraph() {
    Highcharts.setOptions({
      global: {
          useUTC: false
      }
    })

    this.chart = Highcharts.stockChart(this.mainChartTarget, {
      chart: {
          backgroundColor: "#fff",
          events: {
              load: this.fetchPanelData.bind(this)
          },
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
      },
      accessibility: {
        enabled: false  // TODO: include accessibility module
      },

      rangeSelector: {
          enabled: false,
          inputEnabled: false
      },

      scrollbar: {
          enabled: false
      },

      title: {
          text: ""
      },

      xAxis: {
          lineWidth: 0,
          minorGridLineWidth: 0,
          lineColor: "transparent",
          labels: {
              enabled: true
          },
          minorTickLength: 0,
          tickLength: 0
      },

      series: [{
          name: "Panels",
          data: [],
          marker: {
              enabled: true,
              radius: 4
          },
          color: "#ec6961"
      }],

      tooltip: {
          shared: true,
          split: false,
          style: { fontSize: "0.9em" }
      },

      navigator: {
          enabled: false
      }
    })

    this.chart.showLoading("Loading data from server...")
  }

  fetchPanelData(e) {
    var requestUrl = $(this.element).data("href")

    var records = []
    var dates = []
    var request =
      $.ajax({
        url: requestUrl,
        dataType: "json",
        xhrFields: { withCredentials: true }
      })
      .done((data) => {
        var aggregate_count = 0

        $.each(data.panels, function (index, item) {
            aggregate_count += item[1]
            dates.push(item[0])
            records.push([item[0], aggregate_count])
        })

        this.chart.series[0].setData(records)
        this.chart.xAxis[0].setCategories(dates)
      }).fail(() => {
        this.chart.showLoading("Something went wrong. We are not able to show a bar.")
      })

    request.complete(() => {
        this.chart.hideLoading()
    })
  }
}
