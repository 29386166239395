import ApplicationController from "../application_controller"
export default class extends ApplicationController {
  static targets = ["paymentAmountInput", "addPaymentButton"]

  connect() {
    this.toggleAddPaymentButton()
  }

  toggleAddPaymentButton() {
    if (!this.hasAddPaymentButtonTarget || !this.hasPaymentAmountInputTarget) return

    const paymentAmountValue = Number(this.paymentAmountInputTarget.value.replace(/[^0-9\.-]+/g, ""))

    if (paymentAmountValue === 0) {
      this.addPaymentButtonTarget.disabled = true
    }
    else {
      this.addPaymentButtonTarget.disabled = false
    }
  }
}