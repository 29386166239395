import { Controller } from "stimulus"

export default class extends Controller {
  static values = { length: Number, character: String }

  pad() {
    const field = this.element
    const padChar = this.characterValue
    const maxLength = this.lengthValue

    // Remove any existing padding characters from the beginning
    let value = field.value.replace(new RegExp(`^${padChar}+`), "")

    // Truncate the value if it exceeds maxLength
    value = value.slice(-maxLength)

    // Left-pad the value to reach maxLength
    field.value = value.padStart(maxLength, padChar)
  }
}
