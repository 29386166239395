import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    $(this.element).on("scroll", function () {
      $(".selectize-dropdown").hide()
      $(".selectize-input").removeClass("focus input-active dropdown-active")
      $("div.selectize-input > input").blur()
    })
  }
}
