import SelectController from "./select_controller"
import env from "../../shared/env"

export default class extends SelectController {
  static values = {
    url: String,
    exclude: Array,
    httpMethod: String,
  }

  initialize() {
    this.selectizeOptions ||= {}

    if (this.hasUrlValue) {
      this.configureSelectize()
    }
  }

  configureSelectize() {
    this.selectizeOptions.preload = "focus"
    this.selectizeOptions.load = (query, callback) => this.selectizeLoad(query, callback)
    if (env === "test") { this.selectizeOptions.loadThrottle = null }
  }

  async selectizeLoad(query, callback) {
    const url = this.urlValue
    const data = { q: query }
    this.appendExcludeValues(data)

    this.enterLoadingState()

    try {
      const response = await this.performRequest(url, data)
      const responseData = await response.json()
      this.handleSuccess(responseData, callback)
    } catch (error) {
      this.handleError(error)
    } finally {
      this.leaveLoadingState()
    }
  }

  appendExcludeValues(data) {
    if (this.hasExcludeValue) {
      const currentExcludes = data.exclude || []
      data.exclude = [...currentExcludes, ...this.excludeValue]
    }
  }

  handleSuccess(data, callback) {
    if (data.data) {
      data = data.data
    }

    const optgroups = this.extractOptgroups(data)
    this.addOptionGroups(optgroups)

    callback(data)
  }

  extractOptgroups(data) {
    return [...new Set(data.map(item => item.optgroup))].filter(Boolean)
  }

  addOptionGroups(optgroups) {
    optgroups.forEach(optgroup => {
      this.element.selectize.addOptionGroup(optgroup, { label: optgroup })
    })
  }

  handleError(error) {
    console.error("Error loading options:", error)
  }

  performRequest(url, data) {
    const csrfToken = this.getCsrfToken()
    const options = this.buildFetchOptions(data, csrfToken)
    const requestUrl = this.determineRequestUrl(url, data, options)

    return fetch(requestUrl, options)
  }

  getCsrfToken() {
    const csrfTokenElement = document.querySelector("meta[name=\"csrf-token\"]")
    return csrfTokenElement ? csrfTokenElement.getAttribute("content") : ""
  }

  buildFetchOptions(data, csrfToken) {
    return {
      credentials: "include",
      redirect: "follow",
      headers: {"Accept": "application/json"},
      method: this.httpMethodValue?.toUpperCase() === "POST" ? "POST" : "GET",
      body: this.httpMethodValue?.toUpperCase() === "POST" ? JSON.stringify(data) : undefined,
      csrfToken
    }
  }

  determineRequestUrl(url, data, options) {
    if (options.method === "POST") {
      options.headers["Content-Type"] = "application/json"
      options.headers["X-CSRF-Token"] = options.csrfToken
      return url
    } else {
      return `${url}?${this.serializeDataForGetRequest(data)}`
    }
  }

  serializeDataForGetRequest(data) {
    return Object.entries(data).map(([key, value]) => {
      return Array.isArray(value) ?
        value.map(item => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`).join("&") :
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    }).join("&")
  }

  excludeValueChanged() {
    this.element.selectize?.clearOptions()
  }
}
