import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  open() {
    const rect = this.element.getBoundingClientRect()
    const contextMenuContent = this.element.querySelector(".context-menu__content")
    contextMenuContent.style.top = rect.top + "px"
    contextMenuContent.style.left = rect.left + "px"
  }

  close() {
    this.element.open = false
  }

  disconnect() {
    this.close()
  }
}
