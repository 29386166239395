import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["editModal", "confirmationModal", "editForm", "singleKeyEditTemplate", "multipleKeysEditTemplate",
    "singleKeyEditTitleTemplate", "multipleKeysEditTitleTemplate", "bulkUpdateCheckbox", "skipSingleKeyConfirmation",
    "skipMultipleKeysConfirmation"
  ]

  connect() {
    this.skip_single_key_confirmation_id = "skip_single_key_confirmation"
    this.skip_multiple_keys_confirmation_id = "skip_multiple_keys_confirmation"

    this.confirmationModal = $(this.confirmationModalTarget.querySelector(".modal"))
  }

  showConfirmationDialog(event) {
    if((this.bulkUpdateCheckboxTarget.checked && localStorage.getItem(this.skip_multiple_keys_confirmation_id) == undefined)) {
      this.showDialog(event)
    }
    else if(!this.bulkUpdateCheckboxTarget.checked && localStorage.getItem(this.skip_single_key_confirmation_id) == undefined) {
      this.showDialog(event)
    }
  }

  cancelChanges(event) {
    event.preventDefault()

    this.confirmationModal.modal("hide")
    $(this.editModalTarget.querySelector(".modal")).modal("show")
  }

  checkIfSingleKeyConfirmationSkipped() {
    if(this.skipSingleKeyConfirmationTarget.checked) {
      localStorage.setItem(this.skip_single_key_confirmation_id, true)
    }
  }

  checkIfMultipleKeysConfirmationSkipped() {
    if(this.skipMultipleKeysConfirmationTarget.checked) {
      localStorage.setItem(this.skip_multiple_keys_confirmation_id, true)
    }
  }

  submitForm(event) {
    event.preventDefault()

    this.confirmationModal.modal("hide")

    Rails.fire(this.editFormTarget, "submit")
  }

  loadBody() {
    if(this.bulkUpdateCheckboxTarget.checked) {
      return this.multipleKeysEditTemplateTarget.innerHTML
    }
    else {
      return this.singleKeyEditTemplateTarget.innerHTML
    }
  }

  loadTitle() {
    if(this.bulkUpdateCheckboxTarget.checked) {
      return this.multipleKeysEditTitleTemplateTarget.innerHTML
    }
    else {
      return this.singleKeyEditTitleTemplateTarget.innerHTML
    }
  }

  showDialog(event) {
    event.preventDefault()
    const bodyTemplate = this.loadBody()
    const titleTemplate = this.loadTitle()

    $(this.editModalTarget.querySelector(".modal")).modal("hide")
    $(this.confirmationModalTarget.querySelector(".modal-body")).html(bodyTemplate)
    $(this.confirmationModalTarget.querySelector(".modal-title")).html(titleTemplate)
    this.confirmationModal.modal("show")
  }
}
