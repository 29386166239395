import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["additionalOptions"]
  static outlets = ["toggle"]

  connect() {
    this.toggleOutlets.forEach(toggle => {
      this.toggleAdditionalOptionsFor(toggle.toggleInputTarget)
    })
  }

  toggleInstalled(event) {
    this.toggleOutlets.forEach(toggle => {
      if (toggle.toggleInputTarget != event.target) {
        if (event.target.checked) {
          toggle.check()
          toggle.enable()
        } else {
          toggle.uncheck()
          toggle.disable()
        }
        this.toggleAdditionalOptionsFor(toggle.toggleInputTarget)
      }
    })
  }

  toggleAdditionalOptions(event) {
    this.toggleAdditionalOptionsFor(event.target)
  }

  toggleAdditionalOptionsFor(toggleInput) {
    const rowIndex = toggleInput.closest("tr").rowIndex
    const nextRow = toggleInput.closest("tr").parentNode.rows[rowIndex + 1]

    if (nextRow && this.isAdditionalOptionsRow(nextRow)) {
      nextRow.classList.toggle("hidden", !toggleInput.checked)
    }
  }

  isAdditionalOptionsRow(row) {
    return this.additionalOptionsTargets.includes(row)
  }
}
