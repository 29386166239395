import ApplicationController from "./application_controller"

export default class FileUploadController extends ApplicationController {
  static targets = ["fileInputField", "cachedDataField", "imageUrlField", "imagePreviewContainer", "errorContainer", "uploadButton", "removeImageCheckbox"]
  static FILE_UPLOAD_URL = "/file_upload"
  static TRASHBIN_SVG = "<span data-action='click->file-upload#removeOldImage'> \
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'> \
    <circle cx='10.5' cy='10.5' r='10.5' fill='#7E40B8'/> \
    <path d='M9 9.33333V13.3333M11.6667 9.33333V13.3333M5 6.66667H15.6667M15 6.66667L14.422 14.7613C14.3981 15.0977 14.2475 15.4125 14.0007 15.6424C13.754 15.8722 13.4292 16 13.092 16H7.57467C7.23743 16 6.91271 15.8722 6.66592 15.6424C6.41914 15.4125 6.26861 15.0977 6.24467 14.7613L5.66667 6.66667H15ZM12.3333 6.66667V4.66667C12.3333 4.48986 12.2631 4.32029 12.1381 4.19526C12.013 4.07024 11.8435 4 11.6667 4H9C8.82319 4 8.65362 4.07024 8.52859 4.19526C8.40357 4.32029 8.33333 4.48986 8.33333 4.66667V6.66667H12.3333Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/> \
    </svg></span>"

  connect () {
    if (this.imageUrlFieldTarget.value) {
      this.uploadButtonTarget.style.display = "none"
    }
  }

  upload() {
    fetch(
      FileUploadController.FILE_UPLOAD_URL, {
        method: "POST", body: this.buildFormData(),
        credentials: "same-origin",
        headers: {
        "X-CSRF-Token": this.csrfToken(),
        "accept": "application/json"
        }
      }
    )
    .then(this.handleResponse)
    .then(result => this.handleSuccess(result))
    .catch(result => {
      this.handleError(result)
    })
  }

  handleResponse(response) {
    return response.json()
    .then(json => {
      if (response.ok) {
        return json
      } else {
        return Promise.reject(json)
      }
    })
  }

  handleSuccess(result) {
    if (this.hasImagePreviewContainerTarget)
      {this.updateImagePreview(result.url)}
    this.cachedDataFieldTarget.value = JSON.stringify(result.upload_data)
    this.errorContainerTarget.innerHTML = ""
    if (this.hasImageUrlFieldTarget)
      {this.imageUrlFieldTarget.value = result.url}
  }

  handleError(result) {
    this.removeOldImage()
    this.errorContainerTarget.innerHTML = JSON.parse(result.errors).join(",")
    if (this.hasImageUrlFieldTarget)
      {this.imageUrlFieldTarget.value = ""}
  }

  buildFormData() {
    const uploadedFile = this.fileInputFieldTarget.files[0]
    const uploader = this.fileInputFieldTarget.dataset.uploader
    const store = this.fileInputFieldTarget.dataset.store

    const formData = new FormData()
    formData.append("file", uploadedFile)
    formData.append("uploader", uploader)
    formData.append("store", store)
    return formData
  }

  csrfToken() {
    return document.getElementsByName("csrf-token")[0].content
  }

  updateImagePreview(url) {
    const imagePreviewContainer = this.imagePreviewContainerTarget
    const newImage = document.createElement("img")
    const newImageWrapper = document.createElement("div")
    newImageWrapper.className = "img-overlay-wrap"
    newImageWrapper.innerHTML = FileUploadController.TRASHBIN_SVG

    this.removeOldImage()
    newImage.src = url
    newImage.width = this.imagePreviewContainerTarget.dataset.imageWidth
    imagePreviewContainer.appendChild(newImageWrapper)
    newImageWrapper.appendChild(newImage)
    this.uploadButtonTarget.style.display = "none"
    this.imagePreviewContainerTarget.style.display = "block"
    this.removeImageCheckboxTarget.checked = false
  }

  removeOldImage() {
    if (!this.hasImagePreviewContainerTarget)
      {return}

    const oldImage = this.imagePreviewContainerTarget.getElementsByTagName("img")[0]
    if (oldImage) {
      this.removeImageCheckboxTarget.checked = true
      this.uploadButtonTarget.style.display = "block"
      this.imagePreviewContainerTarget.style.display = "none"
      oldImage.remove()
    }
  }
}
