import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = ["oneTimeSelectHide", "oneTimeSelectShow", "oneTimeCheckbox", "kind"]

    connect() {
        this.renderOneTime()
    }
    oneTimeChanged(_event) {
        this.renderOneTime()
    }

    renderOneTime() {
        if (!this.hasOneTimeCheckboxTarget) {
            return
        }

        if (this.oneTimeCheckboxTarget.checked) {
            this.oneTimeSelectHideTargets.forEach((element) => {
                element.classList.add("hidden")
            })
            this.oneTimeSelectShowTargets.forEach((element) => {
                element.classList.remove("hidden")
            })
            this.kindTarget.value = "one_time"
            return
        }
        this.oneTimeSelectHideTargets.forEach((element) => {
            element.classList.remove("hidden")
        })
        this.oneTimeSelectShowTargets.forEach((element) => {
            element.classList.add("hidden")
        })
        this.kindTarget.value = "custom"
    }

}
