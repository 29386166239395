import SelectWithSearchController from "../select_with_search_controller"

export default class extends SelectWithSearchController {
  static values = {
    createUnitPrompt: {
      type: String,
      default: "<button data-action='tenant-form#createUnit'>Add new Unit</button>"
    }
  }

  initialize() {
    super.initialize()

    this.selectizeOptions.plugins ||= {}
  }

  connect() {
    if (this.createUnitPromptValue != "") {
      this.selectizeOptions.plugins.dropdown_header = {
        title: this.createUnitPromptValue,
        closeClass: "hidden"
      }
    }

    super.connect()
  }
}
