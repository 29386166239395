import ApplicationController from "../application_controller"

/**
 * itemWrapperSelectorValue defines the wrapper element selector
 * keyValue defines controllers alike to get options disabled/enabled when selection is made in current controller
 */

export default class extends ApplicationController {
  static values = {
    itemWrapperSelector: String,
    key: String
  }

  disableOption(data) {
    data.disabled = true
    this.updateOption(data)
  }

  disableSelection(controllers) {
    controllers.forEach(controller => {
      if (this.element.value !== "") {
        const data = controller.selectize.options[this.element.value] ||
          {
            text: this.element.options[0].text,
            value: this.element.value
          }

        this.disableOption({
          selectize: controller.selectize,
          ...data
        })
      }
    })
  }

  enableOption(data) {
    data.disabled = false
    this.updateOption(data)
  }

  enablePreviousSelection(controllers) {
    controllers.forEach(controller => {
      if (this.previousSelection !== undefined) {
        const data = controller.selectize.options[this.previousSelection.value] ||
          {
            text: this.previousSelection.text,
            value: this.previousSelection.value
          }

        this.enableOption({
          selectize: controller.selectize,
          ...data
        })
      }
    })
  }

  getControllers() {
    const controllers = this.application.controllers.filter(controller => {
      return controller.identifier === this.identifier &&
        this.keyValue === controller.keyValue &&
        this.element.value !== controller.element.value
    }).map(controller => {
      return this.application.getControllerForElementAndIdentifier(controller.element, "select-with-search")
    })

    return controllers
  }

  initialize() {
    super.initialize()

    this.previousSelection = undefined
    if (this.element.value !== "") {
      this.preserveCurrentValue()
    }

    // the wrapper element gets hidden on delete, so we will create an observer to catch this change
    if (this.hasItemWrapperSelectorValue) {
      this.wrapper = this.element.closest(this.itemWrapperSelectorValue)
      if (this.wrapper) {
        this.observer = new MutationObserver(() => {
          if (this.wrapper.style.display === "none") {
            this.element.value = ""
            this.select()
          }
        })
        this.observer.observe(this.wrapper, {attributes: true, childList: true})
      }
    }

    // allow a little time for other "select-with-search" controllers to initialize
    setTimeout(() => { this.updateOptions() }, 200)
  }

  preserveCurrentValue() {
    this.previousSelection = {
      text: this.element.options[0].text,
      value: this.element.value
    }
  }

  select(event) {
    const controllers = this.getControllers()

    this.enablePreviousSelection(controllers)
    this.disableSelection(controllers)

    this.preserveCurrentValue()
  }

  updateOption(data) {
    const selectize = data.selectize
    delete data.selectize
    selectize.updateOption(data.value, data)
  }

  updateOptions() {
    const controllers = this.getControllers()

    const selectizeController = this.application.getControllerForElementAndIdentifier(this.element, "select-with-search")

    if (selectizeController) {
      controllers.forEach(controller => {
        if (controller.element.value == "") {
          return
        }

        const data = selectizeController.selectize.options[controller.element.value] ||
          {
            text: controller.element.options[0].text,
            value: controller.element.value
          }

        this.disableOption({
          selectize: selectizeController.selectize,
          ...data
        })
      })
    }
  }
}
