import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["building", "door"]

  static values = {
      loadUnitsUrl: String
  }

  connect() {
    this.hideDoorsIfBuildingNotSelected()
    this.checkIfBuildingSelected()
    this.selectDefaultDoorOption()
    this.assignedUnit = this.doorTarget.selectize.getValue()
  }

  hideDoorsIfBuildingNotSelected() {
    if(this.buildingTarget.selectize.getValue() == "") {
      this.doorTarget.selectize.disable()
    }
  }

  selectDefaultDoorOption() {
    if(this.buildingTarget.selectize.getValue() != "" && this.doorTarget.selectize.getValue() == "") {
      this.addCommonLockOption(this.doorTarget.selectize)
      this.doorTarget.selectize.addItem("common_lock", true)
    }
  }

  checkIfBuildingSelected() {
    const selectedBuilding = this.buildingTarget.selectize
    const selectedDoor = this.doorTarget.selectize

    selectedBuilding.on("change", function() {
      selectedDoor.clear()

      if(selectedBuilding.getValue() == "") {
        selectedDoor.disable()
      }
      else {
        selectedDoor.enable()
      }
    })
  }

  loadUnits() {
    const selectize = this.doorTarget.selectize
    this.appendAssignedText(selectize)
    selectize.clearOptions()

    if(this.buildingTarget.selectize.getValue() != "") {
      $.ajax({
        type: "GET",
        url: this.loadUnitsUrlValue + "/?building_id=" + this.buildingTarget.value,
        accept: "json",
        xhrFields: {
          withCredentials: true
        },
        success: (response) => {
          this.addCommonLockOption(selectize)
          this.addUnassignOption(selectize)

          selectize.load(
             (callback) => {
              selectize.addOptionGroup("unit_locks", { label: "" })
              response.units.map((unit) => Object.assign(unit, { optgroup: "unit_locks" }))
              callback(response.units)
            }
          )
        }
      })
    }
  }

  addCommonLockOption(selectize) {
    selectize.addOption({ text: "Common Lock", value: "common_lock" })
    selectize.refreshOptions(false)
  }  addUnassignOption(selectize) {
    selectize.addOption({ text: "Unassigned", value: "unassigned"})
    selectize.refreshOptions(false)
  }

  appendAssignedText(selectize) {
    if(selectize.getValue() === "") return

    else if(selectize.getValue() == this.assignedUnit && !selectize.options[this.assignedUnit].text.includes(" (Assigned)")) {
      selectize.options[this.assignedUnit].text += (" (Assigned)")
    }
  }
}
