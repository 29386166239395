import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["searchInput"]

  dispatchSearchEvent() {
    const event = new CustomEvent("airbnb:mobile-search:search-listings", { detail: { search_query: this.searchInputTarget.value } })

    window.dispatchEvent(event)
  }
}
