import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["swipeToOpenMainCheckbox", "swipeToOpenSubCheckbox","syncVksMainCheckbox", "syncVksSubCheckbox"]

  connect() {
    if(this.swipeToOpenSubCheckboxTargets.length > 0) {
      this.setStateForSwipeToOpenMainCheckbox()
    }
    if(this.swipeToOpenSubCheckboxTargets.length > 0) {
      this.setStateForSyncVksMainCheckbox()
    }
  }

  toggleSwipeToOpen() {
    if(this.swipeToOpenMainCheckboxTarget.checked == true) {
      this.swipeToOpenSubCheckboxTargets.forEach(checkbox => checkbox.checked = true)
    }
    else {
      this.swipeToOpenSubCheckboxTargets.forEach(checkbox => checkbox.checked = false)
    }
  }


  toggleSyncVks() {
    if(this.syncVksMainCheckboxTarget.checked == true) {
      this.syncVksSubCheckboxTargets.forEach(checkbox => checkbox.checked = true)
    }
    else {
      this.syncVksSubCheckboxTargets.forEach(checkbox => checkbox.checked = false)
    }
  }

  setStateForSwipeToOpenMainCheckbox() {
    const all_checked =
      this.swipeToOpenSubCheckboxTargets.every((el, i) => {
        if(el.checked == true) {
          return true
        }
        else {
          return false
        }
      })

    if(all_checked == true) {
      this.swipeToOpenMainCheckboxTarget.checked = true
    }
  }

  setStateForSyncVksMainCheckbox() {
    const all_checked =
      this.syncVksSubCheckboxTargets.every((el, i) => {
        if(el.checked == true) {
          return true
        }
        else {
          return false
        }
      })

    if(all_checked == true) {
      this.syncVksMainCheckboxTarget.checked = true
    }
  }
}
