import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "attachments",
    "attachmentTemplate",
    "attachableSelect"
  ]

  connect() {
    if (this.attachments.length == 0) this.addAttachment()
    this.excludeAttached()
  }

  addAttachment() {
    const id = new Date().getTime()
    const content = this.attachmentTemplateTarget.innerHTML.replace(/NEW_ATTACHMENT/g, id)
    this.attachmentsTarget.insertAdjacentHTML("beforeend", content)

    return this.element.querySelector(`#new_permission_group_attachment_${id}`)
  }

  excludeAttached() {
    const excludeIds = this.attachableSelectTargets
      .filter(field => this.attachments.some(container => container.contains(field)))
      .filter(field => field.value !== "")
      .map(field => field.value)

    this.attachableSelectTargets.forEach(field => {
      field.dataset.selectWithSearchExcludeValue = JSON.stringify(excludeIds)
    })
  }

  // Returns attachments that haven't been removed.
  //
  // TODO: Use Stimulus outlets if custom outlet names ever get implemented:
  // https://github.com/hotwired/stimulus/issues/717
  get attachments() {
    return Array.from(this.attachmentsTarget.children).filter(attachment => {
      const style = window.getComputedStyle(attachment)
      return style.display !== "none"
    })
  }
}
