import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["reader", "deviceType", "os"]

  connect() {
    this.toggleReader()
    this.captureOsSelection()
    this.updateOsSelection()
  }

  toggleReader() {
    this.readerTarget.classList.toggle("hidden", !this.cloudControllers())
    if (!this.cloudControllers()) this.readerTarget.value = ""
  }

  captureOsSelection() {
    this.originalOsTarget = this.osTarget.cloneNode(true)
  }

  updateOsSelection() {
    this.restoreOsTarget()

    if (this.deviceTypeTarget.value != "") {
      this.clearOsGroups()
      this.sortOsOptions()
    }
  }

  clearOsGroups() {
    const deviceType = Array.from(this.deviceTypeTarget.selectedOptions)[0].text

    Array.from(this.osTarget.querySelectorAll("optgroup")).forEach(optgroup => {
      if (optgroup.label.includes(deviceType)) {
        optgroup.replaceWith(...optgroup.childNodes)
      } else {
        optgroup.remove()
      }
    })
  }

  sortOsOptions() {
    const sortedOptions = Array.from(this.osTarget.options)
      .sort((option1, option2) => option1.value.localeCompare(option2.value))

    const currentValue = this.osTarget.value
    this.osTarget.replaceChildren(...sortedOptions)
    this.osTarget.value = currentValue
  }

  restoreOsTarget() {
    const currentValue = this.osTarget.value
    this.osTarget.innerHTML = this.originalOsTarget.innerHTML
    this.osTarget.value = currentValue
  }

  cloudControllers() {
    return this.deviceTypeTarget.value === "CloudBasedAccessController"
  }
}
