import ReactAppController from "./react_app_controller"

export default class extends ReactAppController {

    constructor(props) {
        super(props)
        this.name = "camera_app_controller"
    }

    renderApp() {
        const appConfig = {
            buildingId: this.element.getAttribute("data-building-id"),
            locale: this.element.getAttribute("data-locale"),
        }
        if (appConfig.buildingId) {
            this.app = __bmx__initCameraBuildingApp(this.element, appConfig)
        } else {
            this.app = __bmx__initCameraGlobalApp(this.element, appConfig)
        }
    }
}
