import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["assigned", "unassigned", "assignedTab", "unassignedTab", "submitButton"]

  connect() {
    if (this.unassignedTargets.length > 0) {
      this.submitButtonTargets.forEach(submit => submit.disabled = false)
      this.showUnassigned()
      this.unassignedTabTarget.classList.add("active")
    }
    else {
      this.showAssigned()
      this.assignedTabTarget.classList.add("active")
    }
  }

  showAssigned() {
   this.hideItems(this.unassignedTargets)
   this.showItems(this.assignedTargets)
   this.triggerSearch()
  }

  showUnassigned() {
    this.showItems(this.unassignedTargets)
    this.hideItems(this.assignedTargets)
    this.triggerSearch()
  }

  hideItems(items) {
    items.forEach(item => {
      item.classList.add("hidden")
      item.classList.remove("tabFiltered")
    })
  }

  showItems(items) {
    items.forEach(item => {
      item.classList.remove("hidden")
      item.classList.add("tabFiltered")
    })
  }

  triggerSearch() {
    const event = new CustomEvent("airbnb:apply-search")

    window.dispatchEvent(event)
  }

  enableSubmitButton() {
    this.submitButtonTargets.forEach(submit => submit.disabled = false)
  }
}
