import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  ID_INTERPOLATION = encodeURI("{ID}")
  MODE_INTERPOLATION = encodeURI("{MODE}")

  static outlets = ["bulk-selection"]
  static targets = ["button", "buttonForm", "selectedItemsCount"]
  static values = {
    noneSelectedText: String
  }

  connect() {
    // There is no bulk action we can take until the outlet connects, therefore
    // we prevent the user from interacting with the buttons
    if (!this.hasBulkSelectionOutlet) this.disableButtons()
  }

  clearSelection(event) {
    event?.preventDefault()

    if (this.hasBulkSelectionOutlet) {
      this.bulkSelectionOutlet.setAllDeSelected(event)
    }
  }

  buttonFormTargetConnected(element) {
    if (element.action) element.dataset.originalUrl = element.action
  }

  buttonFormTargetDisconnected(element) {
    if (element.dataset.originalUrl) element.action = element.dataset.originalUrl
  }

  buttonTargetConnected(element) {
    if (element.href) element.dataset.originalUrl = element.href
  }

  buttonTargetDisconnected(element) {
    if (element.dataset.originalUrl) element.href = element.dataset.originalUrl
  }

  bulkSelectionOutletConnected(outlet, element) {
    element.addEventListener("bulk-selection:didChangeMode", this.handleBulkSelectionEvent.bind(this))
    element.addEventListener("bulk-selection:didChangeIds", this.handleBulkSelectionEvent.bind(this))

    this.updateState(outlet)
  }

  handleBulkSelectionEvent(event) {
    this.updateState(event.detail.controller)
  }

  updateState(bulkSelectionController) {
    this.updateUrls(bulkSelectionController.mode, bulkSelectionController.ids)

    var count = bulkSelectionController.count
    if (this.hasNoneSelectedTextValue && count == 0) count = this.noneSelectedTextValue

    this.selectedItemsCountTargets.forEach(element => {
      element.innerText = count
    })

    if (bulkSelectionController.noneSelected) {
      this.disableButtons()
    }
    else {
      this.enableButtons()
    }
  }

  updateUrls(mode, ids) {
    this.buttonTargets.forEach(button => {
      if (!button.dataset.originalUrl) return
      const newURL = this.urlFor(button.dataset.originalUrl, mode, ids)

      if (button.hasAttribute("disabled")) {
        button.dataset.previousUrl = newURL
      }
      else {
        button.href = newURL
      }
    })

    this.buttonFormTargets.forEach(form => {
      if (!form.dataset.originalUrl) return
      const newURL = this.urlFor(form.dataset.originalUrl, mode, ids)

      if (form.hasAttribute("disabled")) {
        form.dataset.previousUrl = newURL
      }
      else {
        form.action = newURL
      }
    })
  }

  urlFor(template, mode, ids) {
    var sanitizedIds = ids

    // If we don't add some ID then the URL will be invalid
    if (sanitizedIds.length == 0) sanitizedIds = ["0-placeholder"]

    return template
      .replace(this.ID_INTERPOLATION, encodeURI(sanitizedIds.join(",")))
      .replace(this.MODE_INTERPOLATION, encodeURI(mode || "ONLY"))
  }

  enableButtons() {
    this.buttonTargets.forEach(button => {
      const previousUrl = button.dataset.previousUrl
      if (previousUrl)  {
        button.href = previousUrl
        button.removeAttribute("data-previous-url")
        button.removeAttribute("data-modal-action-prevent-navigation")
      }

      button.classList.remove("disabled")
      button.removeAttribute("disabled")
    })

    this.buttonFormTargets.forEach(form => {
      const previousUrl = form.dataset.previousUrl
      if (previousUrl)  {
        form.action = previousUrl
        form.removeAttribute("data-previous-url")
      }

      form.removeAttribute("disabled")
    })
  }

  disableButtons() {
    this.buttonTargets.forEach(button => {
      if (button.href && !button.dataset.previousUrl) {
        button.dataset.modalActionPreventNavigation = "true"
        button.dataset.previousUrl = button.href
        button.removeAttribute("href")
      }

      button.classList.add("disabled")
      button.setAttribute("disabled", "disabled")
    })

    this.buttonFormTargets.forEach(form => {
      if (form.action && !form.dataset.previousUrl) {
        form.dataset.previousUrl = form.action
        form.removeAttribute("action")
      }

      form.setAttribute("disabled", "disabled")
    })
  }

}
