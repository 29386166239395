import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  DEFAULT_SELECTED_TAB_CLASS = "active"
  DEFAULT_DESELECTED_TAB_CLASS = "nav-tabs__tab--deselected"
  DEFAULT_SELECTED_VIEW_CLASS = "nav-tabs-view--selected"
  DEFAULT_DESELECTED_VIEW_CLASS = "nav-tabs-view--deselected"

  static targets = [ "tab", "view" ]
  static classes = [ "selectedTab", "selectedView", "deselectedTab", "deselectedView" ]
  static values = {
    selected: String
  }

  connect() {
    if (this.hasSelectedValue) {
      this.switchToTab(this.findTab(this.selectedValue))
    }
    else {
      this.switchToTab(this.tabTargets[0])
    }
  }

  findTab(name) {
    if (!name) return null

    for (const i in this.tabTargets) {
      const tab = this.tabTargets[i]
      if (tab.dataset.target === name) {
        return tab
      }
    }

    return null
  }

  switchTab(event) {
    if (!event.target) return

    let tab = null
    if (event.target.dataset.tabsTarget === "tab") {
      tab = event.target
    }
    else {
      tab = event.target.closest("[data-tabs-target=\"tab\"]")
    }

    if (!tab) return

    event.preventDefault()
    this.switchToTab(tab)
  }

  switchToTab(tab) {
    if (!tab) return

    this.deselectAllTabs()
    this.selectTab(tab)
  }

  deselectAllTabs() {
    for(const i in this.tabTargets) {
      const tab = this.tabTargets[i]
      this.deselectTab(tab)
    }
  }

  deselectTab(tab) {
    if (!tab) return

    tab.classList.remove(this._selectedTabClass)
    tab.classList.add(this._deselectedTabClass)

    const view = this.findViewForTab(tab)
    if (!view) return

    view.classList.remove(this._selectedViewClass)
    view.classList.add(this._deselectedViewClass)
  }

  selectTab(tab) {
    if (!tab) return

    const view = this.findViewForTab(tab)
    if (!view) return

    tab.classList.remove(this._deselectedTabClass)
    tab.classList.add(this._selectedTabClass)
    view.classList.remove(this._deselectedViewClass)
    view.classList.add(this._selectedViewClass)
  }

  findViewForTab(tab) {
    if (!tab) return

    for (const i in this.viewTargets) {
      const view = this.viewTargets[i]
      if (tab.dataset.target === view.dataset.viewName) {
        return view
      }
    }

    return null
  }

  get _selectedTabClass() {
    if (this.hasSelectedTabClass) return this.selectedTabClass

    return this.DEFAULT_SELECTED_TAB_CLASS
  }

  get _deselectedTabClass() {
    if (this.hasDeselectedTabClass) return this.deselectedTabClass

    return this.DEFAULT_DESELECTED_TAB_CLASS
  }

  get _selectedViewClass() {
    if (this.hasSelectedViewClass) return this.selectedViewClass

    return this.DEFAULT_SELECTED_VIEW_CLASS
  }

  get _deselectedViewClass() {
    if (this.hasDeselectedViewClass) return this.deselectedViewClass

    return this.DEFAULT_DESELECTED_VIEW_CLASS
  }
}
