import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["select", "input"]

  connect() {
    this.hideInput()

    $(this.selectTarget).selectize({
      allowEmptyOption: true,
      onChange: (value) => {
        if (value === "other") {
          this.showInput()
        } else {
          this.hideInput()
        }
      }
    })
  }

  showInput() {
    $(this.inputTarget).parent().show()
    $(this.inputTarget).prop("disabled", false)
  }

  hideInput() {
    $(this.inputTarget).parent().hide()
    $(this.inputTarget).prop("disabled", true)
  }
}
