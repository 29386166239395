import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static classes = [ "active", "wrapperActive" ]
  static values = {
    wrapperSelector: String
  }

  connect() {
    if (this.hasWrapperSelectorValue) {
      this.previousActiveWrapperSelection = this.element.querySelector(`${this.wrapperSelectorValue}.${this.activeWrapperClass}`)
      this.previousActiveSelection = this.previousActiveWrapperSelection?.querySelector(`.${this.activeClass}`)
    } else {
      this.previousActiveSelection = this.element.querySelector(`.${this.activeClass}`)
    }
  }

  get activeClass() {
    let activeClass = "active"
    if (this.hasActiveClass) {
      activeClass = this.activeClass
    }
    return activeClass
  }

  get activeWrapperClass() {
    let activeClass = "active"
    if (this.hasActiveWrapperClass) {
      activeClass = this.hasActiveWrapperClass
    }
    return activeClass
  }

  switchActive(event) {
    event.target.classList.add(this.activeClass)

    if (this.hasWrapperSelectorValue) {
      const wrapper = event.target.closest(this.wrapperSelectorValue)
      wrapper?.classList?.add(this.activeWrapperClass)
      this.previousActiveWrapperSelection?.classList?.remove(this.activeWrapperClass)
      this.previousActiveWrapperSelection = wrapper
    }

    this.previousActiveSelection?.classList?.remove(this.activeClass)
    this.previousActiveSelection = event.target
  }
}
