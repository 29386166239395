import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  connect() {
    super.connect()
    if (this.element.dataset.flatpickrNarrow === "true") {
      this.fp.calendarContainer.classList.add("narrow")
    }
  }

  open() {
    if (this.parentPreventingFocus && !document.body.contains(this.parentPreventingFocus)) {
      this.parentPreventingFocus = null
    }

    this.parentPreventingFocus ||= this.findParentPreventingFocus()

    if (this.parentPreventingFocus) {
      this.parentPreventingFocus.removeAttribute("tabindex")
    }
  }

  close() {
    if (this.parentPreventingFocus) {
      this.parentPreventingFocus.setAttribute("tabindex", -1)
    }
  }

  findParentPreventingFocus() {
    let parent = this.element.parentNode

    while(parent) {
      if (typeof parent.getAttribute !== "function") return null

      if (parent.getAttribute("tabindex") === "-1") return parent

      parent = parent.parentNode
    }

    return null
  }
}
