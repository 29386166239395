import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { length: Number }

  trim(event) {
    const field = event.target
    const value = field.value.trim()

    const trimLength = this.lengthValue

    if (value.length > trimLength) {
      field.value = value.slice(-trimLength)
    }
  }
}
