import ApplicationController from "./application_controller"

const LEAVING_PAGE_MESSAGE = "You have unsaved changes to your settings. Do you want to save your changes before leaving this page?"
export default class extends ApplicationController {
    static targets = []

    formIsChanged(event) {
        this.setChanged("true")
    }

    leavingPage(event) {
        if (this.isFormChanged()) {
            if (event.type == "turbo:before-visit") {
                if (window.confirm(LEAVING_PAGE_MESSAGE)) {
                    event.preventDefault()
                }
            } else {
                event.returnValue = LEAVING_PAGE_MESSAGE
                return event.returnValue
            }
        }
    }

    allowFormSubmission(event) {
        this.setChanged("false")
    }

    setChanged(changed) {
        this.data.set("changed", changed)
    }

    isFormChanged() {
        return this.data.get("changed") == "true"
    }
}
