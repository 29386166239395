export default class JsonUtils {
    static jsonOrString(str) {
        let val
        try {
            val = JSON.parse(str)
        } catch (e) {
            return str
        }
        return val
    }
}