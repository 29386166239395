import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [
    "schedules",
    "scheduleTemplate",
    "destroy",
    "allTimes",
    "addScheduleButton",
    "attachableSelect",
  ]

  static outlets = [
    "schedule",
  ]

  static values = {
    selectedDevice: Object,
  }

  connect() {
    if (this.schedulesTarget.children.length == 0) this.addSchedule()
    if (this.hasSelectedDeviceValue) this.setSelectedDevice()
    this.handleLock()

    // delay until next tick so that schedule controller are connected
    setTimeout(() => { this.syncAllTimes() }, 0)
  }

  setSelectedDevice() {
    const select = this.attachableSelectTarget

    if (!select) return
    select.innerHTML = `<option value="${this.selectedDeviceValue.value}">${this.selectedDeviceValue.text}</option>`
  }
  addSchedule() {
    const content = this.scheduleTemplateTarget.innerHTML.replace(/NEW_SCHEDULE/g, new Date().getTime())
    this.schedulesTarget.insertAdjacentHTML("beforeend", content)
  }

  defaultAllTimes() {
    if (this.emptySchedule()) this.enableAllTimes()
  }

  syncAllTimes() {
    this.allTimesTarget.checked = this.allTimes()
    this.toggleAllTimes()
  }

  toggleAllTimes() {
    if (this.allTimesTarget.checked) {
      this.enableAllTimes()
    } else {
      this.disableAllTimes()
    }
  }

  disableAllTimes() {
    this.allTimesTarget.checked = false
    this.addScheduleButtonTarget.disabled = false
  }

  enableAllTimes() {
    this.allTimesTarget.checked = true
    if (!this.allTimes()) this.setAllTimes()
    this.addScheduleButtonTarget.disabled = true
  }

  setAllTimes() {
    this.scheduleOutlets.forEach(schedule => schedule.remove())
    this.addSchedule()
    // wait for next tick so that the new schedule controller gets connected
    setTimeout(() => { this.lastScheduleOutlet.setAllTimes() }, 0)
  }

  emptySchedule() {
    return this.scheduleOutlets.length === 1 && this.scheduleOutlets[0].empty()
  }

  allTimes() {
    const schedules = this.scheduleOutlets.filter(schedule => !schedule.removed())
    return schedules.length === 1 && schedules[0].allTimes()
  }

  handleLock() {
    const selectedDeviceText = this.attachableSelectTarget.options[this.attachableSelectTarget.selectedIndex].text

    if (selectedDeviceText.includes("RemoteLock")) {
      this.disableAccessTimes()
    } else {
      this.enableAccessTimes()
    }
  }

  disableAccessTimes() {
    if (this.allTimesTarget.disabled) return

    this.enableAllTimes()
    this.allTimesTarget.disabled = true
    this.lastScheduleOutlet.disable()
    this.setOpacity(0.5)
  }

  enableAccessTimes() {
    if (!this.allTimesTarget.disabled) return

    this.disableAllTimes()
    this.allTimesTarget.disabled = false
    this.lastScheduleOutlet.enable()
    this.setOpacity(1)
  }

  setOpacity(value) {
    const elements = [this.schedulesTarget, this.allTimesTarget.parentElement, this.addScheduleButtonTarget]
    elements.forEach(element => element.style.opacity = value)
  }

  remove(event) {
    // using the ":prevent" action option changes the order of actions:
    // https://github.com/hotwired/stimulus/issues/718
    event?.preventDefault()

    this.destroyTarget.value = "1"
    this.element.classList.add("hidden")
  }

  get lastScheduleOutlet() {
    return this.scheduleOutlets[this.scheduleOutlets.length - 1]
  }
}
