import ApplicationController from "./application_controller"
import { useDebounce } from "stimulus-use"

export default class extends ApplicationController {
  static targets = [ "emailInput", "informationView", "unitInformationView",
                     "contactInformationView", "presentationView"]
  static values = {
    debounceInterval: Number
  }

  static debounces = [ "checkUserEmail", "updateDisplayNameStrategy" ]

  connect() {
    if (this.debounceIntervalValue) {
      useDebounce(this, { wait: this.debounceIntervalValue })
    }
  }

  checkUserEmail(_event) {
    if (!this.hasEmailInputTarget) return

    const url = new URL(`${window.location}?${Rails.serializeElement(this.element)}`)

    if (this.hasContactInformationViewTarget) this.contactInformationViewTarget.src = url
    if (this.hasInformationViewTarget) this.informationViewTarget.src = url
    if (this.hasPresentationViewTarget) this.presentationViewTarget.src = url
  }

  createUnit(event) {
    const unitId = event?.target?.dataset?.unitId
    if (!unitId) return

    const url = new URL(`${window.location}?${Rails.serializeElement(this.element)}`)
    url.searchParams.set("units_user[unit_id]", unitId)

    if (this.hasUnitInformationViewTarget) this.unitInformationViewTarget.src = url
    if (this.hasPresentationViewTarget) this.presentationViewTarget.src = url
  }


  changeUnit(event) {
    this.updateDisplayNameStrategy(event)
  }

  updateDisplayNameStrategy(_event) {
    if (!this.hasPresentationViewTarget) return

    const url = new URL(`${window.location}?${Rails.serializeElement(this.element)}`)

    this.presentationViewTarget.src = url
  }
}
