import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  HIDDEN_CLASS = "hidden"
  ATTRIBUTES = [
    "active_at",
    "delivery_pass_enabled",
    "display_name_strategy",
    "display_only_on_search",
    "email",
    "inactive_at",
    "pin_enabled",
    "qr_key_enabled",
    "swipe_to_open_enabled",
    "sms"
  ]

  static targets = [
    "activeAtTextField",
    "attributeSelector",
    "deliveryPassEnabledCheckbox",
    "displayNameStrategySelect",
    "displayOnSearchCheckbox",
    "emailNotificationsCheckbox",
    "inactiveAtTextField",
    "pinEnabledCheckbox",
    "smsNotificationsCheckbox",
    "virtualKeyEnabledCheckbox",
    "swipeToOpenEnabledCheckbox"
  ]

  connect() {
    this.reconfigureForm()
  }

  reconfigureForm() {
    if (!this.hasAttributeSelectorTarget) return

    const shownAttributes = this.selectedAttributes

    this.ATTRIBUTES.forEach(attribute => {
      let methodName = this.camelize(`disable_${attribute}`)

      if (shownAttributes.includes(attribute)) {
        methodName = this.camelize(`enable_${attribute}`)
      }

      const method = this[methodName]

      if (method) {
        method.bind(this)()
      }
      else {
        console.warn(`No handler found for '${attribute}': Missing method '${methodName}'`)
      }
    })
  }

  get selectedAttributes() {
    if (!this.hasAttributeSelectorTarget) return []

    return Array
      .from(this.attributeSelectorTarget.selectedOptions)
      .map(option => option.value)
  }

  //////////////////////////////////////////////////////////////////////////////
  //                             Field handlers                               //
  //////////////////////////////////////////////////////////////////////////////

  enableDisplayNameStrategy() {
    if (!this.hasDisplayNameStrategySelectTarget) return
    this.enableSelect(this.displayNameStrategySelectTarget)
  }

  disableDisplayNameStrategy() {
    if (!this.hasDisplayNameStrategySelectTarget) return
    this.disableSelect(this.displayNameStrategySelectTarget)
  }

  enableActiveAt() {
    if (!this.hasActiveAtTextFieldTarget) return
    this.enableCalendar(this.activeAtTextFieldTarget)
  }

  disableActiveAt() {
    if (!this.hasActiveAtTextFieldTarget) return
    this.disableCalendar(this.activeAtTextFieldTarget)
  }

  enableInactiveAt() {
    if (!this.hasInactiveAtTextFieldTarget) return
    this.enableCalendar(this.inactiveAtTextFieldTarget)
  }

  disableInactiveAt() {
    if (!this.hasInactiveAtTextFieldTarget) return
    this.disableCalendar(this.inactiveAtTextFieldTarget)
  }

  enableDeliveryPassEnabled() {
    if (!this.hasDeliveryPassEnabledCheckboxTarget) return
    this.enableCheckbox(this.deliveryPassEnabledCheckboxTarget)
  }

  disableDeliveryPassEnabled() {
    if (!this.hasDeliveryPassEnabledCheckboxTarget) return
    this.disableCheckbox(this.deliveryPassEnabledCheckboxTarget)
  }

  enablePinEnabled() {
    if (!this.hasPinEnabledCheckboxTarget) return
    this.enableCheckbox(this.pinEnabledCheckboxTarget)
  }

  disablePinEnabled() {
    if (!this.hasPinEnabledCheckboxTarget) return
    this.disableCheckbox(this.pinEnabledCheckboxTarget)
  }

  enableDisplayOnlyOnSearch() {
    if (!this.hasDisplayOnlyOnSearchCheckboxTarget) return
    this.enableCheckbox(this.displayOnlyOnSearchCheckboxTarget)
  }

  disableDisplayOnlyOnSearch() {
    if (!this.hasDisplayOnlyOnSearchCheckboxTarget) return
    this.disableCheckbox(this.displayOnlyOnSearchCheckboxTarget)
  }

  enableQrKeyEnabled() {
    if (!this.hasVirtualKeyEnabledCheckboxTarget) return
    this.enableCheckbox(this.virtualKeyEnabledCheckboxTarget)
  }

  disableQrKeyEnabled() {
    if (!this.hasVirtualKeyEnabledCheckboxTarget) return
    this.disableCheckbox(this.virtualKeyEnabledCheckboxTarget)
  }

  enableSwipeToOpenEnabled() {
    if (!this.hasSwipeToOpenEnabledCheckboxTarget) return
    this.enableCheckbox(this.swipeToOpenEnabledCheckboxTarget)
  }

  disableSwipeToOpenEnabled() {
    if (!this.hasSwipeToOpenEnabledCheckboxTarget) return
    this.disableCheckbox(this.swipeToOpenEnabledCheckboxTarget)
  }

  enableDisplayOnlyOnSearch() {
    if (!this.hasDisplayOnSearchCheckboxTarget) return
    this.enableCheckbox(this.displayOnSearchCheckboxTarget)
  }

  disableDisplayOnlyOnSearch() {
    if (!this.hasDisplayOnSearchCheckboxTarget) return
    this.disableCheckbox(this.displayOnSearchCheckboxTarget)
  }

  enableEmail() {
    if (!this.hasEmailNotificationsCheckboxTarget) return
    this.enableCheckbox(this.emailNotificationsCheckboxTarget)
  }

  disableEmail() {
    if (!this.hasEmailNotificationsCheckboxTarget) return
    this.disableCheckbox(this.emailNotificationsCheckboxTarget)
  }

  enableSms() {
    if (!this.hasSmsNotificationsCheckboxTarget) return
    this.enableCheckbox(this.smsNotificationsCheckboxTarget)
  }

  disableSms() {
    if (!this.hasSmsNotificationsCheckboxTarget) return
    this.disableCheckbox(this.smsNotificationsCheckboxTarget)
  }

  //////////////////////////////////////////////////////////////////////////////
  //                           Generic handlers                               //
  //////////////////////////////////////////////////////////////////////////////

  enableTextField(element) {
    if (!element) return

    const group = element.closest(".form-group")
    this.restoreAllInputsWithin(group)
    group.classList.remove(this.HIDDEN_CLASS)
  }

  disableTextField(element) {
    if (!element) return

    const group = element.closest(".form-group")
    this.disableAllInputsWithin(group)
    group.classList.add(this.HIDDEN_CLASS)
  }

  enableCheckbox(element) {
    if (!element) return

    const group = element.closest(".checkbox")
    this.restoreAllInputsWithin(group)
    group.classList.remove(this.HIDDEN_CLASS)
    group.classList.remove("disabled")
  }

  disableCheckbox(element) {
    if (!element) return

    const group = element.closest(".checkbox")
    this.disableAllInputsWithin(group)
    group.classList.add(this.HIDDEN_CLASS)
    group.classList.add("disabled")
  }

  enableSelect(element) {
    if (!element) return

    const group = element.closest(".form-group")
    this.restoreAllInputsWithin(group)
    group.classList.remove(this.HIDDEN_CLASS)

    if (element.selectize) {
      element.selectize.enable()
    }
  }

  disableSelect(element) {
    if (!element) return

    const group = element.closest(".form-group")
    this.disableAllInputsWithin(group)
    group.classList.add(this.HIDDEN_CLASS)

    if (element.selectize) {
      element.selectize.disable()
    }
  }

  enableCalendar(element) {
    this.enableTextField(element)
    element?.nextSibling?.removeAttribute("disabled")
  }

  disableCalendar(element) {
    this.disableTextField(element)
    element?.nextSibling?.setAttribute("disabled", "disabled")
  }

  disableAllInputsWithin(element) {
    if (!element) return

    element.querySelectorAll("input").forEach(input => {
      // Store the previous disabled state so we can restore it later
      // But only if it isn't already set! We don't want to overwrite it
      if (!input.dataset.previouslyDisabled) {
        input.dataset.previouslyDisabled = input.disabled
      }
      input.disabled = true
    })
  }

  restoreAllInputsWithin(element) {
    if (!element) return

    element.querySelectorAll("input").forEach(input => {
      if (input.dataset.previouslyDisabled === "true") {
        input.disabled = true
      }
      else {
        input.removeAttribute("disabled")
      }

      input.removeAttribute("data-previously-disabled")
    })
  }
}
