import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["contentToHide", "toggleableField", "kindSelect"]

  connect() {
    if (this.hasKindSelectTarget) {
      this.hideAndDisableExcept(this.kindSelectTarget.value)
    }
  }

  toggleContent(event) {
    if (event.target.checked) {
      event.target.nextElementSibling.children[0].innerText = "Enabled"
      this.contentToHideTargets.map((target) => target.classList.remove("hidden"))
    } else {
      event.target.nextElementSibling.children[0].innerText = "Off"
      this.contentToHideTargets.map((target) => target.classList.add("hidden"))
    }
  }

  showFieldsForCurrentKind(event) {
    this.hideAndDisableExcept(event.target.value)
  }

  hideAndDisableExcept(fieldName) {
    this.toggleableFieldTargets.map((field) => {
      if (fieldName === field.dataset.fieldName) {
        field.classList.remove("hidden")
        // querySelectorAll returns a NodeList, we cast it to Array for easier iteration
        Array.from(field.querySelectorAll("input")).map((el) => el.disabled = false)
      } else {
        field.classList.add("hidden")
        Array.from(field.querySelectorAll("input")).map((el) => el.disabled = true)
      }
    })
  }
}
