import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "anchor" ]
  static values = {
    offset: Number
  }

  connect() {
    this.attachEventListener()
    this.hilightCurrentAnchor()
  }

  disconnect() {
    this.destroyEventListener()
  }

  attachEventListener() {
    if (this.scrollEventHandler) return

    this.scrollEventHandler = this.hilightCurrentAnchor.bind(this)
    window.addEventListener("scroll", this.scrollEventHandler)
  }

  destroyEventListener() {
    if (!this.scrollEventHandler) return

    window.removeEventListener("scroll", this.scrollEventHandler)
    this.scrollEventHandler = null
  }

  hilightCurrentAnchor(_event) {
    if (!this.hasAnchorTarget) return

    const offset = this.offsetValue + 1

    const anchorTargets =
      this.anchorTargets
      .map((element) => {
        this.markAnchorAs(element, "inactive")
        return this.findAnchorTarget(element)
      })
      .filter((element) => {
        if (!element) return false

        return element._yOffset <= offset
      })
      .sort((a, b) => {
        return a._yOffset - b._yOffset
      })

    const activeTarget = anchorTargets[anchorTargets.length - 1]
    if (!activeTarget) return

    this.markAnchorAs(activeTarget._anchor, "active")
  }

  findAnchorTarget(element) {
    const href = element.href
    if (!href) return null

    const selector = `#${href.split("#")[1]}`
    const targetElement = document.querySelector(selector)

    if (!targetElement) return null

    targetElement._anchor = element
    targetElement._yOffset = targetElement.getBoundingClientRect().y

    return targetElement
  }

  markAnchorAs(element, state) {
    const closestSelector = element.dataset.targetClosest

    if (closestSelector) {
      const closesElement = element.closest(closestSelector)
      if (!closesElement) return

      element = closesElement
    }

    if (state == "active") {
      element.classList.add("active")
    }
    else {
      element.classList.remove("active")
    }
  }

  scrollToElement(event) {
    event.preventDefault()

    const element = event.target

    if (!element) return

    const anchorTarget = this.findAnchorTarget(element)

    window.scrollBy({
      top: anchorTarget._yOffset - this.offsetValue,
      behavior: "smooth"
    })
  }
}
