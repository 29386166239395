import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "daySelect",
    "dayButton"
  ]

  static values = {
    buttonSelectedClass: String,
    buttonUnselectedClass: String
  }

  BUTTON_SELECTED_CLASS = "btn-primary"
  BUTTON_UNSELECTED_CLASS = "btn-unselected"

  connect() {
    this.dayButtonTargets.forEach((target) => {
      const value = target.dataset.dayValue
      this.setDaySelection(target, this.getValueSelected(value))
    })
  }

  toggleDay(event) {
    if (!event.target) return
    this.setDaySelection(event.target, !this.buttonIsActive(event.target))
  }

  setDaySelection(target, selected) {
    if (!target) return

    const value = target.dataset.dayValue
    this.setDayButtonSelected(target, selected)
    this.setValueSelected(value, selected)
  }

  setDayButtonSelected(target, selected) {
    if (!target) return

    if (selected) {
      target.classList.add(this.buttonSelectedClass)
      target.classList.remove(this.buttonUnselectedClass)
    }
    else {
      target.classList.remove(this.buttonSelectedClass)
      target.classList.add(this.buttonUnselectedClass)
    }
  }

  buttonIsActive(target) {
    if (!target) return

    return target.classList.contains(this.buttonSelectedClass)
  }

  get buttonSelectedClass() {
    if (this.hasButtonSelectedClassValue) return this.buttonSelectedClassValue

    return this.BUTTON_SELECTED_CLASS
  }

  get buttonUnselectedClass() {
    if (this.hasButtonUnselectedClassValue) return this.buttonUnselectedClassValue

    return this.BUTTON_UNSELECTED_CLASS
  }

  setValueSelected(value, selected) {
    if (!this.hasDaySelectTarget) return

    const targets = this.daySelectTarget.querySelectorAll(`[value=${value}]`)

    targets.forEach((node) => {
      node.selected = selected
    })
  }

  getValueSelected(value) {
    if (!this.hasDaySelectTarget) return

    const targets = this.daySelectTarget.querySelectorAll(`[value=${value}]`)

    let selected = true

    targets.forEach((node) => {
      selected = selected && node.selected
    })

    return selected
  }
}
