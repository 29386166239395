import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["unassignedListingsNotificationModal"]
  static values = {
    unassignedListingsCount: Number
  }

  connect() {
    if(this.unassignedListingsCountValue > 0 && sessionStorage.getItem("notification_already_shown") == undefined) {
      $(this.unassignedListingsNotificationModalTarget).modal("show")
      sessionStorage.setItem("notification_already_shown", true)
    }
  }
}
