import ApplicationController from "./application_controller"
import { Sortable } from "@shopify/draggable"

export default class extends ApplicationController {
  static values = { buildingHandle: String }

  connect() {
    const sortable = new Sortable(this.element, { draggable: "tr" })
    sortable.on("sortable:stop", (event) => {
      this.updateInactivePanelMessage({
        position: event.newIndex + 1,
        id: event.dragEvent.data.source.dataset.id,
      })
    })
  }

  updateInactivePanelMessage(params) {
    $.ajax({
      url: "/buildings/" + this.buildingHandleValue + "/inactive_panel_messages/" + params.id,
      data: { position: params.position },
      beforeSend: function(xhr) {
        xhr.setRequestHeader("X-CSRF-Token", $("meta[name=\"csrf-token\"]").attr("content"))
      },
      type: "PUT",
      dataType: "json"
   })
  }
}
