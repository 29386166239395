/* Iteratively searches the given element and it's parents for a given dataset
 * field. If a value is provided then the dataset field must mach the expected
 * value, if no expected value is given then the dataset is checked for the
 * presence of the field.
 */
export default function(element, datasetField, options) {
  if (!element) return false

  options = options || {}
  const expectedValue = options.expectedValue
  const maxDepth = options.maxDepth

  let depth = 0
  let parent = element

  while(parent) {
    if (maxDepth && (depth > maxDepth)) return false
    else if (expectedValue === undefined && parent.dataset && (datasetField in parent.dataset)) return true
    else if (expectedValue !== undefined && parent.dataset && parent.dataset[datasetField] === expectedValue) return true

    parent = parent.parentNode
    depth = depth + 1
  }

  return false
}
